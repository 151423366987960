import { Role } from "@/acl/roles";
import { CITE_GESTION_PRODUCTS_DETAILS } from "@/assets/constants/products/cite-gestion";

export default {
  orgName: "Cité Gestion",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/cite-gestion.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/cite-gestion.png"),
  },
  products: CITE_GESTION_PRODUCTS_DETAILS,
  hasHoldingData: true,
  hasUpdatablePriceData: true,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en"],
  followFeatureActivated: true,
};
