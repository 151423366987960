import { Role } from "@/acl/roles";
import { MIRANTE_PRODUCTS_DETAILS } from "@/assets/constants/products/mirante";
import { i18n } from "@/main.js";

export default {
  orgName: "MFM Mirante Fund Management",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/mirante.png"),
  redirectButtonNavBar: {
    getUrl: () => "https://mirante.ch/",
    getText: () => i18n.t("wlps.mirante.redirectButtonNavBarText"),
    isInternal: false,
  },
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/mirante.jpeg"),
  },
  products: MIRANTE_PRODUCTS_DETAILS,
  hasHoldingData: true,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "fr", "de"],
  followFeatureActivated: true,
};
