import { Role } from "@/acl/roles";
import { DEMO_PRODUCTS_DETAILS } from "@/assets/constants/products/demo";
import { i18n } from "@/main.js";

export default {
  orgName: "Demo AM",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/demo.png"),
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/demo.jpg"),
    darkRatio: 0.2,
  },
  navBarLogoStyle: "max-height: 60px; max-width: 100%",
  products: DEMO_PRODUCTS_DETAILS,
  hasHoldingData: true,
  hasUpdatablePriceData: true,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "fr", "de"],
  followFeatureActivated: true,
  customSections: [
    {
      getTitle: () => i18n.t("wlps.demo.customSections.useCases.title"),
      subSections: [
        {
          navMenuItem: {
            url: "/use-cases/alpinum",
            getName: () => i18n.t("wlps.demo.customSections.useCases.alpinum.title"),
            slug: "use-case-alpinum",
            icon: "CircleIcon",
          },
          router: {
            path: "/use-cases/alpinum",
            name: "useCases_alpinum",
            component: () => import("../views/customSections/demo/useCases/Alpinum.vue"),
          },
        },
      ],
    },
  ],
};
