import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITH_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

const dmcFundWorldHyCorporateBondsIUsd = {
  productId: "dmc-fund-world-hy-corporate-bonds-i-usd",
  storeModule: "dmcFundWorldHyCorporateBondsIUsd",
  productName: "DMC Fund World HY Corporate Bonds",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

export const ONE_SWISS_BANK_PRODUCTS_DETAILS = [dmcFundWorldHyCorporateBondsIUsd];

export const ONE_SWISS_BANK_PRODUCTS = ONE_SWISS_BANK_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
