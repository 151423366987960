import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

// ******---------- Quality Schweiz Equity Fund ----------*****
const qualitySchweizEquityFundB = {
  productId: "quality-schweiz-equity-fund-b",
  storeModule: "qualitySchweizEquityFundB",
  productName: "Quality Schweiz Equity Fund B",
  productShortName: "Quality Schweiz Equity Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "quality-schweiz-equity-fund",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const qualitySchweizEquityFundC = cloneDeep(qualitySchweizEquityFundB);
qualitySchweizEquityFundC.productId = "quality-schweiz-equity-fund-c";
qualitySchweizEquityFundC.storeModule = "qualitySchweizEquityFundC";
qualitySchweizEquityFundC.productName = "Quality Schweiz Equity Fund C";
qualitySchweizEquityFundC.isDefaultShareClass = false;

const qualitySchweizEquityFundD = cloneDeep(qualitySchweizEquityFundB);
qualitySchweizEquityFundD.productId = "quality-schweiz-equity-fund-d";
qualitySchweizEquityFundD.storeModule = "qualitySchweizEquityFundD";
qualitySchweizEquityFundD.productName = "Quality Schweiz Equity Fund D";
qualitySchweizEquityFundD.isDefaultShareClass = false;

// ******---------- Quality Europa Equity Fund ----------*****
const qualityEuropaEquityFundB = {
  productId: "quality-europa-equity-fund-b",
  storeModule: "qualityEuropaEquityFundB",
  productName: "Quality Europa Equity Fund B",
  productShortName: "Quality Europa Equity Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "quality-europa-equity-fund",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const qualityEuropaEquityFundC = cloneDeep(qualityEuropaEquityFundB);
qualityEuropaEquityFundC.productId = "quality-europa-equity-fund-c";
qualityEuropaEquityFundC.storeModule = "qualityEuropaEquityFundC";
qualityEuropaEquityFundC.productName = "Quality Europa Equity Fund C";
qualityEuropaEquityFundC.isDefaultShareClass = false;

const qualityEuropaEquityFundD = cloneDeep(qualityEuropaEquityFundB);
qualityEuropaEquityFundD.productId = "quality-europa-equity-fund-d";
qualityEuropaEquityFundD.storeModule = "qualityEuropaEquityFundD";
qualityEuropaEquityFundD.productName = "Quality Europa Equity Fund D";
qualityEuropaEquityFundD.isDefaultShareClass = false;

// ******---------- Quality USA Equity Fund ----------*****
const qualityUsaEquityFundB = {
  productId: "quality-usa-equity-fund-b",
  storeModule: "qualityUsaEquityFundB",
  productName: "Quality USA Equity Fund B",
  productShortName: "Quality USA Equity Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "quality-usa-equity-fund",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const qualityUsaEquityFundC = cloneDeep(qualityUsaEquityFundB);
qualityUsaEquityFundC.productId = "quality-usa-equity-fund-c";
qualityUsaEquityFundC.storeModule = "qualityUsaEquityFundC";
qualityUsaEquityFundC.productName = "Quality USA Equity Fund C";
qualityUsaEquityFundC.isDefaultShareClass = false;

const qualityUsaEquityFundD = cloneDeep(qualityUsaEquityFundB);
qualityUsaEquityFundD.productId = "quality-usa-equity-fund-d";
qualityUsaEquityFundD.storeModule = "qualityUsaEquityFundD";
qualityUsaEquityFundD.productName = "Quality USA Equity Fund D";
qualityUsaEquityFundD.isDefaultShareClass = false;

// ******---------- Aramus Japan Equity Fund ----------*****
const aramusJapanEquityFundChf = {
  productId: "aramus-japan-equity-fund-chf",
  storeModule: "aramusJapanEquityFundChf",
  productName: "Aramus Japan Equity Fund CHF",
  productShortName: "Aramus Japan Equity Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "aramus-japan-equity-fund",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const aramusJapanEquityFundJpy = cloneDeep(aramusJapanEquityFundChf);
aramusJapanEquityFundJpy.productId = "aramus-japan-equity-fund-jpy";
aramusJapanEquityFundJpy.storeModule = "aramusJapanEquityFundJpy";
aramusJapanEquityFundJpy.productName = "Aramus Japan Equity Fund JPY";
aramusJapanEquityFundJpy.isDefaultShareClass = false;

const aramusJapanEquityFundUsd = cloneDeep(aramusJapanEquityFundChf);
aramusJapanEquityFundUsd.productId = "aramus-japan-equity-fund-usd";
aramusJapanEquityFundUsd.storeModule = "aramusJapanEquityFundUsd";
aramusJapanEquityFundUsd.productName = "Aramus Japan Equity Fund USD";
aramusJapanEquityFundUsd.isDefaultShareClass = false;

const aramusJapanEquityFundEur = cloneDeep(aramusJapanEquityFundChf);
aramusJapanEquityFundEur.productId = "aramus-japan-equity-fund-eur";
aramusJapanEquityFundEur.storeModule = "aramusJapanEquityFundEur";
aramusJapanEquityFundEur.productName = "Aramus Japan Equity Fund EUR";
aramusJapanEquityFundEur.isDefaultShareClass = false;

// ******---------- Global Quality Top 15 Fund ----------*****
const globalQualityTop15FundREur = {
  productId: "global-quality-top-15-fund-r-eur",
  storeModule: "globalQualityTop15FundREur",
  productName: "Global Quality Top 15 Fund R EUR",
  productShortName: "Global Quality Top 15 Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "global-quality-top-15-fund",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const globalQualityTop15FundRChf = cloneDeep(globalQualityTop15FundREur);
globalQualityTop15FundRChf.productId = "global-quality-top-15-fund-r-chf";
globalQualityTop15FundRChf.storeModule = "globalQualityTop15FundRChf";
globalQualityTop15FundRChf.productName = "Global Quality Top 15 Fund R CHF";
globalQualityTop15FundRChf.isDefaultShareClass = false;

const globalQualityTop15FundIEur = cloneDeep(globalQualityTop15FundREur);
globalQualityTop15FundIEur.productId = "global-quality-top-15-fund-i-eur";
globalQualityTop15FundIEur.storeModule = "globalQualityTop15FundIEur";
globalQualityTop15FundIEur.productName = "Global Quality Top 15 Fund I EUR";
globalQualityTop15FundIEur.isDefaultShareClass = false;

const globalQualityTop15FundIChf = cloneDeep(globalQualityTop15FundREur);
globalQualityTop15FundIChf.productId = "global-quality-top-15-fund-i-chf";
globalQualityTop15FundIChf.storeModule = "globalQualityTop15FundIChf";
globalQualityTop15FundIChf.productName = "Global Quality Top 15 Fund I CHF";
globalQualityTop15FundIChf.isDefaultShareClass = false;

const globalQualityTop15FundDEur = cloneDeep(globalQualityTop15FundREur);
globalQualityTop15FundDEur.productId = "global-quality-top-15-fund-d-eur";
globalQualityTop15FundDEur.storeModule = "globalQualityTop15FundDEur";
globalQualityTop15FundDEur.productName = "Global Quality Top 15 Fund D EUR";
globalQualityTop15FundDEur.isDefaultShareClass = false;

const globalQualityTop15FundDChf = cloneDeep(globalQualityTop15FundREur);
globalQualityTop15FundDChf.productId = "global-quality-top-15-fund-d-chf";
globalQualityTop15FundDChf.storeModule = "globalQualityTop15FundDChf";
globalQualityTop15FundDChf.productName = "Global Quality Top 15 Fund D CHF";
globalQualityTop15FundDChf.isDefaultShareClass = false;

// ******---------- Quality Top 8 Certificate ----------*****
const qualityTop8CertificateChf = {
  productId: "quality-top-8-certificate-chf",
  storeModule: "qualityTop8CertificateChf",
  productName: "Quality Top 8 Certificate CHF",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Quality Asia Top 15 ----------*****
const qualityAsiaTop15Usd = {
  productId: "quality-asia-top-15-usd",
  storeModule: "qualityAsiaTop15Usd",
  productName: "Quality Asia Top 15 USD",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- HQAM x OHOR Sustainable Quality Certificate ----------*****
const hqamXOhorSustainableQualityCertificateChf = {
  productId: "hqam-x-ohor-sustainable-quality-certificate-chf",
  storeModule: "hqamXOhorSustainableQualityCertificateChf",
  productName: "HQAM x OHOR Sustainable Quality Certificate CHF",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Quality Corporate Bonds USD ----------*****
const qualityCorporateBondsUsd = {
  productId: "quality-corporate-bonds-usd",
  storeModule: "qualityCorporateBondsUsd",
  productName: "Quality Corporate Bonds USD",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Quality Corporate Bonds EUR ----------*****
const qualityCorporateBondsEur = {
  productId: "quality-corporate-bonds-eur",
  storeModule: "qualityCorporateBondsEur",
  productName: "Quality Corporate Bonds EUR",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Quality P2P Fond ----------*****
const qualityP2pFondI = {
  productId: "quality-p2p-fond-i",
  storeModule: "qualityP2pFondI",
  productName: "Quality P2P Fond I",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- HQAM Real Estate Portfolio ----------*****
const hqamRealEstatePortfolio = {
  productId: "hqam-real-estate-portfolio",
  storeModule: "hqamRealEstatePortfolio",
  productName: "HQAM Real Estate Portfolio",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Quality Global Composite ----------*****
const qualityGlobalComposite = {
  productId: "quality-global-composite",
  storeModule: "qualityGlobalComposite",
  productName: "Quality Global Composite",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Global Quality Top 8 Strategie ----------*****
const globalQualityTop8Strategie = {
  productId: "global-quality-top-8-strategie",
  storeModule: "globalQualityTop8Strategie",
  productName: "Global Quality Top 8 Strategie",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Global Quality Top 15 Strategie ----------*****
const globalQualityTop15Strategie = {
  productId: "global-quality-top-15-strategie",
  storeModule: "globalQualityTop15Strategie",
  productName: "Global Quality Top 15 Strategie",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Quality Europe Composite ----------*****
const qualityEuropeComposite = {
  productId: "quality-europe-composite",
  storeModule: "qualityEuropeComposite",
  productName: "Quality Europe Composite",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Quality USA Composite ----------*****
const qualityUsaComposite = {
  productId: "quality-usa-composite",
  storeModule: "qualityUsaComposite",
  productName: "Quality USA Composite",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Quality Schweiz Composite ----------*****
const qualitySchweizComposite = {
  productId: "quality-schweiz-composite",
  storeModule: "qualitySchweizComposite",
  productName: "Quality Schweiz Composite",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

export const HQAM_PRODUCTS_DETAILS = [
  qualitySchweizEquityFundB,
  qualitySchweizEquityFundC,
  qualitySchweizEquityFundD,
  qualityEuropaEquityFundB,
  qualityEuropaEquityFundC,
  qualityEuropaEquityFundD,
  qualityUsaEquityFundB,
  qualityUsaEquityFundC,
  qualityUsaEquityFundD,
  aramusJapanEquityFundChf,
  aramusJapanEquityFundJpy,
  aramusJapanEquityFundUsd,
  aramusJapanEquityFundEur,
  globalQualityTop15FundREur,
  globalQualityTop15FundRChf,
  globalQualityTop15FundIEur,
  globalQualityTop15FundIChf,
  globalQualityTop15FundDEur,
  globalQualityTop15FundDChf,
  qualityTop8CertificateChf,
  qualityAsiaTop15Usd,
  hqamXOhorSustainableQualityCertificateChf,
  qualityCorporateBondsUsd,
  qualityCorporateBondsEur,
  qualityP2pFondI,
  hqamRealEstatePortfolio,
  qualityGlobalComposite,
  globalQualityTop8Strategie,
  globalQualityTop15Strategie,
  qualityEuropeComposite,
  qualityUsaComposite,
  qualitySchweizComposite,
];

export const HQAM_PRODUCTS = HQAM_PRODUCTS_DETAILS.map((details) => details.storeModule);
