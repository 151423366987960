import bulkComponentsUpdate from "./operations/bulkComponentsUpdate";
import bulkPriceDataUpdate from "./operations/bulkPriceDataUpdate";
import generateFactsheets from "./operations/generateFactsheets";

export default {
  namespaced: true,
  modules: {
    bulkPriceDataUpdate,
    generateFactsheets,
    bulkComponentsUpdate,
  },
};
