import { Role } from "@/acl/roles";
import { SSI_PRODUCTS_DETAILS } from "@/assets/constants/products/ssi";

export default {
  orgName: "SSI Asset Management AG",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/ssi.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/ssi.png"),
  },
  products: SSI_PRODUCTS_DETAILS,
  hasHoldingData: true,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en"],
  followFeatureActivated: true,
  colorScheme: [
    "#6D1D45",
    "#F5C498",
    "#F2F0E8",
    "#C5C8D0",
    // Same colors with less opacity
    "#6D1D45B2",
    "#F5C498B2",
    "#F2F0E8B2",
    "#C5C8D0B2",
    "#6D1D457F",
    "#F5C4987F",
    "#F2F0E87F",
    "#C5C8D07F",
    "#6D1D4519",
    "#F5C49819",
    "#F2F0E819",
    "#C5C8D019",
  ],
};
