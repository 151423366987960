const getDefaultState = () => {
  return {
    file: undefined,
    products: undefined,
    benchmarks: undefined,
    dryRunError: undefined,
  };
};

const state = getDefaultState();

const getters = {
  getFile: (state) => {
    return state.file;
  },
  getProducts: (state) => {
    return state.products;
  },
  getBenchmarks: (state) => {
    return state.benchmarks;
  },
  getDryRunError: (state) => {
    return state.dryRunError;
  },
};

const mutations = {
  FETCH_FILE_SUCCESS: (state, { data }) => {
    state.file = data;
  },
  FETCH_PRODUCTS_SUCCESS: (state, { data }) => {
    state.products = data;
  },
  FETCH_BENCHMARKS_SUCCESS: (state, { data }) => {
    state.benchmarks = data;
  },
  FETCH_DRY_RUN_ERROR_SUCCESS: (state, { data }) => {
    state.dryRunError = data;
  },
  RESET_ALL_STATES(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setFile({ commit }, { file }) {
    commit("FETCH_FILE_SUCCESS", {
      data: file,
    });
  },
  setProducts({ commit }, { products }) {
    commit("FETCH_PRODUCTS_SUCCESS", {
      data: products,
    });
  },
  setBenchmarks({ commit }, { benchmarks }) {
    commit("FETCH_BENCHMARKS_SUCCESS", {
      data: benchmarks,
    });
  },
  setDryRunError({ commit }, { dryRunError }) {
    commit("FETCH_DRY_RUN_ERROR_SUCCESS", {
      data: dryRunError,
    });
  },
  resetAllStates({ commit }) {
    commit("RESET_ALL_STATES");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
