import { Role } from "@/acl/roles";
import { HQAM_PRODUCTS_DETAILS } from "@/assets/constants/products/hqam";

export default {
  orgName: "HQAM",
  showHomePageHeader: false,
  logoPath: require("@/assets/images/logo/hqam.png"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  allowedLoginMethods: ["Google"],
  products: HQAM_PRODUCTS_DETAILS,
  hasUpdatablePriceData: true,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "de"],
};
