import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { EventDate, GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";
import { i18n } from "@/main.js";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

const convexus = {
  productId: "convexus",
  storeModule: "convexus",
  productName: "Alquant Convexus",
  productShortName: "Convexus",
  productColor: ORG_COLOR,
  showLastPrice: true,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.liveText"),
  },
  historical: {
    mode: "historical",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.historicalText"),
  },
  internal: {
    mode: "internal",
    updateFrequency: UpdateFrequency.daily,
    componentPath: "alquant/convexus/InternalAnalysis.vue",
    allowedRoles: INTERNAL_ALLOWED_ROLES,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    allowLiveComparison: true,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.cumulativeWeightedReturns,
      GenericProductCards.returnProfile,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19Crisis,
      EventDate.convexusInception,
    ],
  },
  tabmenuItems: [
    {
      url: "/products/convexus/live-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.live"),
      slug: "convexus-live-analysis",
    },
    {
      url: "/products/convexus/historical-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.historical"),
      slug: "convexus-historical-analysis",
    },
    {
      url: "/products/convexus/internal-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.internal"),
      slug: "convexus-internal-analysis",
      meta: {
        authorize: INTERNAL_ALLOWED_ROLES,
      },
    },
  ],
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const proTech = {
  productId: "protech",
  storeModule: "proTech",
  productName: "Alquant ProTech",
  productShortName: "ProTech",
  productColor: ORG_COLOR,
  showLastPrice: true,
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Product inception",
      desc: "ProTech was issued by UBS and was made available to all investors in Switzerland",
      time: "31 Jul 2020",
    },
  ],
  tabmenuItems: [
    {
      url: "/products/protech/live-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.live"),
      slug: "protech-live-analysis",
    },
    {
      url: "/products/protech/historical-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.historical"),
      slug: "protech-historical-analysis",
    },
    {
      url: "/products/protech/internal-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.internal"),
      slug: "protech-internal-analysis",
      meta: {
        authorize: INTERNAL_ALLOWED_ROLES,
      },
    },
  ],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.liveText"),
  },
  historical: {
    mode: "historical",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19CrisisForHistoricalProtech,
    ],
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.historicalText"),
  },
  internal: {
    mode: "internal",
    updateFrequency: UpdateFrequency.daily,
    componentPath: "alquant/proTech/InternalAnalysis.vue",
    allowedRoles: INTERNAL_ALLOWED_ROLES,
    allowLiveComparison: true,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.cumulativeWeightedReturns,
      GenericProductCards.returnProfile,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19Crisis,
      EventDate.protechInception,
    ],
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const alvola = {
  productId: "alvola",
  lockedBehindLogin: true,
  hideOnProductsSection: true,
  storeModule: "alvola",
  productName: "Alquant Alvola",
  productShortName: "Alvola",
  productColor: ORG_COLOR,
  showLastPrice: false,
  allowedRoles: INTERNAL_ALLOWED_ROLES,
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Product inception",
      desc: "Alvola was issued as an SPV in collaboration with ISP Group and was made available to all qualified investors in Switzerland",
      time: "02 Jun 2021",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Long volatility leg adjustment",
      desc: "After intensive research, we have slightly adjusted the exposure management of the long volatility leg to further reduce the maximum drawdown of the strategy",
      time: "15 Dec 2021",
    },
  ],
  tabmenuItems: [
    {
      url: "/products/alvola/internal-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.internal"),
      slug: "alvola-internal-analysis",
      meta: {
        authorize: INTERNAL_ALLOWED_ROLES,
      },
    },
  ],
  live: {
    mode: "internal",
    updateFrequency: UpdateFrequency.daily,
    componentPath: "alquant/alvola/InternalAnalysis.vue",
    allowedRoles: INTERNAL_ALLOWED_ROLES,
    allowLiveComparison: true,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.cumulativeWeightedReturns,
      GenericProductCards.returnProfile,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19Crisis,
      EventDate.alvolaInception,
    ],
  },
  useCase: {
    componentPath: "alquant/alvola/UseCase.vue",
  },
};

const alvolaCHF = {
  productId: "alvola-chf",
  storeModule: "alvolaCHF",
  productName: "Alquant Alvola (CHF)",
  productShortName: "Alvola (CHF)",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowedRoles: INTERNAL_ALLOWED_ROLES.concat([Role.alvolaCHF]),
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Product inception",
      desc: "Alvola CHF was issued by UBS and was made available to all investors in Switzerland",
      time: "15 Oct 2021",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Small adjustment on long volatility leg",
      desc: "After intensive research, we have slightly adjusted the exposure management of the long volatility leg to further reduce the maximum drawdown of the strategy",
      time: "15 Dec 2021",
    },
  ],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const lsts = {
  productId: "lsts",
  storeModule: "lifeSciencesToolsAndServices",
  productName: "Alquant Life Sciences Tools and Services",
  productShortName: "Life Sciences Tools and Services",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowedRoles: INTERNAL_ALLOWED_ROLES.concat([Role.lifeSciencesToolsAndServices]),
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Product inception",
      desc: "Alquant Life Sciences Tools and Services was issued by UBS and was made available to all investors in Switzerland",
      time: "16 Jul 2021",
    },
  ],
  tabmenuItems: [
    {
      url: "/products/lsts/live-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.live"),
      slug: "lsts-live-analysis",
    },
    {
      url: "/products/lsts/historical-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.historical"),
      slug: "lsts-historical-analysis",
    },
    {
      url: "/products/lsts/internal-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.internal"),
      slug: "lsts-internal-analysis",
      meta: {
        authorize: INTERNAL_ALLOWED_ROLES,
      },
    },
  ],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    componentPath: "alquant/lifeSciencesToolsAndServices/LiveAnalysis.vue",
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.liveText"),
  },
  historical: {
    mode: "historical",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.historicalText"),
  },
  internal: {
    mode: "internal",
    updateFrequency: UpdateFrequency.daily,
    allowLiveComparison: true,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.cumulativeWeightedReturns,
      GenericProductCards.returnProfile,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19Crisis,
      EventDate.lstsInception,
    ],
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const ynnovation = {
  productId: "ynnovation",
  storeModule: "ynnovation",
  productName: "Ynnovation",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowedRoles: INTERNAL_ALLOWED_ROLES,
  tabmenuItems: [
    {
      url: "/products/ynnovation/live-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.live"),
      slug: "ynnovation-live-analysis",
      meta: {
        authorize: INTERNAL_ALLOWED_ROLES,
      },
    },
    {
      url: "/products/ynnovation/internal-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.internal"),
      slug: "ynnovation-internal-analysis",
      meta: {
        authorize: INTERNAL_ALLOWED_ROLES,
      },
    },
  ],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    allowedRoles: INTERNAL_ALLOWED_ROLES,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.liveText"),
  },
  internal: {
    mode: "internal",
    updateFrequency: UpdateFrequency.daily,
    componentPath: "alquant/ynnovation/InternalAnalysis.vue",
    allowedRoles: INTERNAL_ALLOWED_ROLES,
    allowLiveComparison: true,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.cumulativeWeightedReturns,
      GenericProductCards.returnProfile,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19Crisis,
      EventDate.ynnovationInception,
    ],
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const alcrypto = {
  productId: "alcrypto",
  dev: true,
  hideOnProductsSection: true,
  storeModule: "alcrypto",
  productName: "Alquant Alcrypto",
  productShortName: "Alcrypto",
  productColor: ORG_COLOR,
  showLastPrice: false,
  allowedRoles: INTERNAL_ALLOWED_ROLES,
  live: {
    mode: "historical", // Historical because this product has no live price yet
    updateFrequency: UpdateFrequency.daily,
    componentPath: "alquant/alcrypto/Analysis.vue",
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: [EventDate.december2018Crisis, EventDate.covid19Crisis],
  },
};

const alvolaWithFutures = {
  productId: "alvola-with-futures",
  dev: true,
  hideOnProductsSection: true,
  storeModule: "alvolaWithFutures",
  productName: "Alquant Alvola (with futures)",
  productShortName: "Alvola (with futures)",
  productColor: ORG_COLOR,
  showLastPrice: false,
  allowedRoles: INTERNAL_ALLOWED_ROLES,
  live: {
    mode: "historical", // Historical because this produdct has no live price yet
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    componentPath: "alquant/alvolaWithFutures/Analysis.vue",
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19Crisis,
      EventDate.alvolaInception,
    ],
  },
  useCase: {
    componentPath: "alquant/alvolaWithFutures/UseCase.vue",
  },
};

const quantCore = {
  productId: "quant-core",
  hideOnProductsSection: true,
  storeModule: "quantCore",
  productName: "Alquant Quant Core",
  productShortName: "Quant Core",
  productColor: ORG_COLOR,
  showLastPrice: false,
  allowedRoles: INTERNAL_ALLOWED_ROLES,
  live: {
    mode: "historical",
    updateFrequency: UpdateFrequency.daily,
    componentPath: "alquant/quantCore/Analysis.vue",
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: [EventDate.december2018Crisis, EventDate.covid19Crisis],
  },
};

const leonteqAlquantDynamicUsEquity = {
  productId: "leonteq-alquant-dynamic-us-equity",
  storeModule: "leonteqAlquantDynamicUsEquity",
  productName: "AQUS - Leonteq Alquant Dynamic US Equity",
  productShortName: "AQUS",
  productColor: ORG_COLOR,
  showLastPrice: true,
  langsForDocuments: ["en", "de", "fr"],
  tabmenuItems: [
    {
      url: "/products/leonteq-alquant-dynamic-us-equity/live-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.live"),
      slug: "leonteq-alquant-dynamic-us-equity-live-analysis",
    },
    {
      url: "/products/leonteq-alquant-dynamic-us-equity/historical-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.historical"),
      slug: "leonteq-alquant-dynamic-us-equity-historical-analysis",
    },
    {
      url: "/products/leonteq-alquant-dynamic-us-equity/internal-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.internal"),
      slug: "leonteq-alquant-dynamic-us-equity-internal-analysis",
    },
  ],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  historical: {
    mode: "historical",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19CrisisForHistoricalProtech,
    ],
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.historicalText"),
  },
  internal: {
    mode: "internal",
    updateFrequency: UpdateFrequency.daily,
    allowedRoles: INTERNAL_ALLOWED_ROLES,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const leonteqAlquantDynamicTechEquity = {
  productId: "leonteq-alquant-dynamic-tech-equity",
  storeModule: "leonteqAlquantDynamicTechEquity",
  productName: "AQUST - Leonteq Alquant Dynamic Tech Equity",
  productShortName: "AQUST",
  productColor: ORG_COLOR,
  showLastPrice: true,
  langsForDocuments: ["en", "de", "fr"],
  tabmenuItems: [
    {
      url: "/products/leonteq-alquant-dynamic-tech-equity/live-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.live"),
      slug: "leonteq-alquant-dynamic-tech-equity-live-analysis",
    },
    {
      url: "/products/leonteq-alquant-dynamic-tech-equity/historical-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.historical"),
      slug: "leonteq-alquant-dynamic-tech-equity-historical-analysis",
    },
    {
      url: "/products/leonteq-alquant-dynamic-tech-equity/internal-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.internal"),
      slug: "leonteq-alquant-dynamic-tech-equity-internal-analysis",
    },
  ],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  historical: {
    mode: "historical",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19CrisisForHistoricalProtech,
    ],
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.historicalText"),
  },
  internal: {
    mode: "internal",
    updateFrequency: UpdateFrequency.daily,
    allowedRoles: INTERNAL_ALLOWED_ROLES,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const leonteqAlquantLongShortUsEquity = {
  productId: "leonteq-alquant-long-short-us-equity",
  storeModule: "leonteqAlquantLongShortUsEquity",
  productName: "AQLS - Leonteq Alquant Long-Short US Equity",
  productShortName: "AQLS",
  productColor: ORG_COLOR,
  showLastPrice: true,
  langsForDocuments: ["en", "de", "fr"],
  tabmenuItems: [
    {
      url: "/products/leonteq-alquant-long-short-us-equity/live-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.live"),
      slug: "leonteq-alquant-long-short-us-equity-live-analysis",
    },
    {
      url: "/products/leonteq-alquant-long-short-us-equity/historical-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.historical"),
      slug: "leonteq-alquant-long-short-us-equity-historical-analysis",
    },
    {
      url: "/products/leonteq-alquant-long-short-us-equity/internal-analysis",
      getName: () => i18n.t("pages.products.analysis.tab.internal"),
      slug: "leonteq-alquant-long-short-us-equity-internal-analysis",
    },
  ],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  historical: {
    mode: "historical",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: [
      EventDate.financialCrisis,
      EventDate.euroCrisis,
      EventDate.december2018Crisis,
      EventDate.covid19CrisisForHistoricalProtech,
    ],
    getTabmenuText: () => i18n.t("pages.products.analysis.tab.historicalText"),
  },
  internal: {
    mode: "internal",
    updateFrequency: UpdateFrequency.daily,
    allowedRoles: INTERNAL_ALLOWED_ROLES,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.volatility,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
      GenericProductCards.yearlyCumulativeReturns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

export const ALQUANT_PRODUCTS_DETAILS = [
  // Temporarily put the Leonteq products before everything (for the duration of the "launch").
  leonteqAlquantDynamicUsEquity,
  leonteqAlquantDynamicTechEquity,
  leonteqAlquantLongShortUsEquity,
  convexus,
  proTech,
  alvola,
  alvolaCHF,
  lsts,
  ynnovation,
  alcrypto,
  alvolaWithFutures,
  quantCore,
];

export const ALQUANT_PRODUCTS_DEV_DETAILS = [alvola, alcrypto, alvolaWithFutures, quantCore];

export const ALQUANT_PRODUCTS = ALQUANT_PRODUCTS_DETAILS.map((details) => details.storeModule);

export const ALQUANT_INTERNAL_ALLOWED_ROLES = INTERNAL_ALLOWED_ROLES;
