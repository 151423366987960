import { Role } from "@/acl/roles";
import { CALMGARD_PRODUCTS_DETAILS } from "@/assets/constants/products/calmgard";

export default {
  orgName: "CALMGARD",
  showHomePageHeader: true,
  homePageLogoPath: require("@/assets/images/logo/calmgard.webp"),
  logoPath: require("@/assets/images/logo/calmgard-black.webp"),
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/calmgard.jpeg"),
  },
  products: CALMGARD_PRODUCTS_DETAILS,
  hasHoldingData: true,
  hasUpdatablePriceData: true,
  possibleRoles: [Role.superAdmin, Role.admin, Role.viewer],
  allowedRoles: [Role.superAdmin, Role.admin, Role.viewer],
  help: {
    knowledgeBase: true,
  },
  admin: true,
  availableLanguages: ["en"],
  followFeatureActivated: true,
  colorScheme: [
    "#D2BB83",
    "#A6C6C5",
    "#2C5A68",
    "#E6B3AC",
    // Same colors with less opacity
    "#D2BB83B2",
    "#A6C6C5B2",
    "#2C5A68B2",
    "#E6B3ACB2",
    "#D2BB837F",
    "#A6C6C57F",
    "#2C5A687F",
    "#E6B3AC7F",
    "#D2BB8319",
    "#A6C6C519",
    "#2C5A6819",
    "#E6B3AC19",
  ],
};
