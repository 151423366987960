import { Role } from "@/acl/roles";
import { EQUINOX_PRODUCTS_DETAILS } from "@/assets/constants/products/equinox";

export default {
  orgName: "EQUINOX",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/equinox.png"),
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/equinox.png"),
  },
  navBarLogoStyle: "max-height: 55px; max-width: 100%",
  products: EQUINOX_PRODUCTS_DETAILS,
  hasHoldingData: true,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["de", "en"],
  followFeatureActivated: true,
};
