import { Role } from "@/acl/roles";
import { LAKEFIELD_PRODUCTS_DETAILS } from "@/assets/constants/products/lakefield";

export default {
  orgName: "Lakefield Partners",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/lakefield.jpg"),
  navBarLogoStyle: "max-height: 50px; max-width: 100%",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/lakefield.png"),
  },
  products: LAKEFIELD_PRODUCTS_DETAILS,
  hasHoldingData: true,
  hasUpdatablePriceData: true,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "de"],
  followFeatureActivated: true,
  colorScheme: [
    "#B60D24",
    "#747474",
    "#003E6D",
    "#3375A7",
    "#6697BD",
    "#99BAD3",
    "#CCDCE9",
    "#B9BABE",
    "#D9DADE",
    "#E3E4E6",
    // Repeat the final color 10 times to ensure it remains the last color in the sequence.
    // There's no need for additional repetitions since the requirement doesn't exceed 20 colors.
    "#E3E4E6",
    "#E3E4E6",
    "#E3E4E6",
    "#E3E4E6",
    "#E3E4E6",
    "#E3E4E6",
    "#E3E4E6",
    "#E3E4E6",
    "#E3E4E6",
    "#E3E4E6",
  ],
};
