import { Role } from "@/acl/roles";
import { BCF_PRODUCTS_DETAILS } from "@/assets/constants/products/bcf";
import { i18n } from "@/main.js";
import router from "@/router/index";

export default {
  orgName: "BCF",
  showHomePageHeader: true,
  redirectButtonNavBar: {
    getUrl: () => router.resolve({ name: "home" }).href,
    getText: () => i18n.t("wlps.bcf.redirectButtonNavBarText"),
    isInternal: true,
  },
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/bcf.jpg"),
  },
  products: BCF_PRODUCTS_DETAILS,
  hasHoldingData: true,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["fr", "de"],
  langInUrl: true,
  hideLoginRegisterButtons: true,
  disableGoogleAnalytics: true,
  customPrivacyPolicyPath: "compliance/custom/bcf/PrivacyPolicy.vue",
  enable2FactorAuthentication: true,
};
