export default {
  admin: {
    disclaimer: "Disclaimer",
    enterText: "Enter a text",
    data: {
      holdingData: {
        exportCsv: "Export CSV of holding data",
        importCsv: "Import CSV with overrides",
        onlyShowOverride: "Only show rows with overriden values:",
        saveChanges: "Save All Changes",
        saveSuccess: "Holding data overrides have been saved successfully.",
        textUniqueKeys:
          "This table displays the base holding data values for all products available on the platform. Each unique entry is identified by a combination of 'Holding Name', 'Currency', and 'ISIN'. If this unique key is shared across multiple products, it will appear as a single entry here.",
        textHowToSingleOverride:
          "To view or modify overrides for a row, click on it to expand the details. Any overridden values that differ from the base values will be highlighted: green text indicates overrides that have already been saved to the system and yellow text highlights unsaved overrides.",
        textHowToBulkOverride:
          "If you want to apply bulk updates, it's recommended to download the current holding data as a CSV file. You can then make edits using a spreadsheet tool (e.g., Microsoft Excel) and re-upload the modified CSV on this page. Any uploaded CSV will first show a preview of the proposed overrides, and you will need to save to apply them.",
        title: "Holding data with overrides",
        unsavedChangesAlert:
          "You have unsaved changes. If you leave without saving, they will be lost.",
        columns: {
          assetClass: "Asset class",
          assetClassOverride: "Asset class override",
          currency: "Currency",
          holdingName: "Holding name",
          holdingNameOverride: "Holding name override",
          instrumentType: "Instrument type",
          instrumentTypeOverride: "Instrument type override",
          invalidValue: "Invalid value",
          isin: "ISIN",
          isinOverride: "ISIN override",
          region: "Region",
          regionOverride: "Region override",
          rowIndex: "Row index",
          sector: "Sector",
          sectorOverride: "Sector override",
        },
        uploadCsvErrorsDialog: {
          close: "Close",
          nonExistentAssetClass: "Non-existent asset class",
          nonExistentKey: "Non-existent keys",
          nonExistentInstrumentType: "Non-existent instrument type",
          nonExistentRegion: "Non-existent region",
          nonExistentSector: "Non-existent sector",
          selectErrorType: "Select Error Type:",
          text: 'The CSV file you provided contains errors. If there are non-existent keys (a key being the combination of holding name, ISIN, and currency), please check for typos in those fields. If there are unknown override values in the columns "assetClassOverride", "instrumentTypeOverride", "sectorOverride", or "regionOverride", please review the available values for these columns.',
          title: "CSV Validation Errors",
        },
        uploadCsvDialog: {
          cancel: "Cancel",
          import: "Import",
          text: "Please import a CSV file in the required format. You can export the CSV to view the expected columns.",
          title: "Import CSV with overrides",
        },
      },
    },
    operations: {
      bulkPriceDataUpdate: {
        benchmarks: "Benchmarks",
        benchmarkName: "Benchmark name",
        products: "Products",
        productName: "Product name",
        metaInfo: {
          title: "Admin - Operations - Bulk Price Data Update",
        },
        dashboard: {
          createdAt: "Created at",
          createdBy: "Created by",
          downloadFile: "Download file",
          fileDownloadError: "Error when downloading the document, please contact us.",
          loadingOperations: "Loading operations ...",
          newUpdate: "New update...",
          refreshButton: "Refresh",
          status: "Status",
          uploadedFile: "Uploaded file",
          statuses: {
            failed: "Failed",
            pending: "Pending",
            running: "Running",
            succeeded: "Succeeded",
          },
        },
        dryRunError: {
          backToUploadCsvButton: "Back to upload CSV",
          csvParsingErrorTitle: "CSV Parsing Error",
          csvParsingErrorText:
            "The file provided is either not a valid CSV file, or it does not have the correct format. Please ensure all rows have values, the columns are correctly ordered, and there are no missing values.",
          numberFormatErrorTitle: "Number Format Error",
          numberFormatErrorText:
            "The numbers in your file could not be parsed. Please ensure numbers follow the correct format: use only standard numeric characters, and use a dot (.) for decimal points. Avoid any other special characters.",
          duplicateDateErrorTitle: "Duplicate Date Error",
          duplicateDateErrorText: `The following IDs contain duplicate dates. Each ID should have unique dates for each entry.`,
          unknownIdsTitle: "Unknown IDs",
          unknownIdsText: `The following IDs are not recognized. Please ensure only valid IDs from the tables on this page are used.`,
          unauthorizedTitle: "Unauthorized IDs",
          unauthorizedText: `The following IDs are not permitted for updates.`,
        },
        preparationAndDryRun: {
          backToDashboardButton: "Back to Dashboard",
          idToUseInCsv: "ID to use in CSV",
          uploadCsvForPreview: "Upload CSV for Preview...",
          title: "Upload CSV for Data Preview",
          csvUploadDialog: {
            acceptText: "Generate Preview",
            title: "Upload CSV for Preview",
            text: "Select a CSV file to preview the price and benchmark updates. This dry run will simulate the changes, allowing you to review them on the next screen before finalizing.",
          },
          explanation: {
            el1: 'The ID column should contain values found in the "ID to use in CSV" column in the tables below.',
            el2: "The DATE column should follow the format YYYY-MM-DD (e.g., 2023-10-15, 1999-01-31).",
            el3: "The PRICE must be a decimal value.",
            para1:
              "To update prices and benchmarks in bulk, please upload a CSV file with three columns: ID, DATE, and PRICE. The order needs to be respected, as well as the presence of the headers in the CSV.",
            para2:
              "All prices that you are allowed to update, be it the prices of a product or a benchmark, are displayed in the table(s) below. If something you are expecting to see is missing, please contact us.",
            tip: '<em>Tip</em>: Click <a href="/sample-bulk-price-data-update.csv" download><u>here</u></a> to download a sample CSV file for reference.',
          },
        },
        reviewAndConfirm: {
          backToUploadCsvButton: "Back to upload CSV",
          confirmAndApplyChangesButton: "Confirm and Apply Changes",
          firstDate: "First date",
          id: "ID",
          lastDate: "Last date",
          name: "Name",
          numberOfRows: "Number of rows",
          reviewAndConfirmText:
            "Here is a summary of the updates to be applied. Please note that the prices scheduled for update will fully replace the existing prices, as the old prices will be deleted before the new ones are inserted. Once you have reviewed the modifications, please confirm at the bottom.",
          title: "Review and Confirm Data Updates",
          successDialog: {
            title: "Operation fulfilled",
            text: "The operation has been successfully fulfilled!",
          },
        },
      },
      generateFactsheets: {
        allLanguages: "All languages",
        allProducts: "All products",
        createdAt: "Created at",
        factsheetGeneration: "Factsheet generation",
        factsheetGenerationFilters: "Factsheet generation filters",
        fileName: "File name",
        generationNotPossibleDate:
          "The selected latest date is earlier than the product's inception date.",
        generationNotPossibleLang: "This language is not available for the selected product.",
        generationPossible: "Factsheet generation is possible.",
        generationWarningFileExists:
          "A file with this name and language already exists. Proceeding will overwrite the existing file.",
        generateSelectedFactsheets: "Generate selected factsheets",
        generationStatus: "Generation status",
        backToDashboardButton: "Back to dashboard",
        backToFiltersButton: "Back to filters",
        language: "Language",
        lastDateToInclude: "Last date to include",
        lastDayOfData: "Last day of data",
        loadingOperations: "Loading operations ...",
        newButton: "Generate new factsheets...",
        preparingFactsheetsGeneration: "Preparing the list of factsheets for generation...",
        product: "Product",
        refreshButton: "Refresh",
        requireLogin: "Require login for access",
        selectFactsheetsForGeneration: "Select Factsheets for Generation",
        selectLanguages: "Select languages",
        selectProducts: "Select products",
        setAsLastFactsheet: "Set as last factsheet for product",
        showAvailableFactsheet: "Show available factsheets",
        status: "Status",
        langMultiselect: {
          placeholder: "Select languages to include",
          selectLabel: "Press enter to select",
          deselectLabel: "Press enter to remove",
          selectedLabel: "Selected",
          limitText: (ctx) => `and ${ctx.named("count")} more`,
        },
        metaInfo: {
          title: "Admin - Operations - Generate Factsheets",
        },
        productsMultiselect: {
          placeholder: "Select products to include",
          selectLabel: "Press enter to select",
          deselectLabel: "Press enter to remove",
          selectedLabel: "Selected",
          limitText: (ctx) => `and ${ctx.named("count")} more`,
        },
        statuses: {
          failed: "Failed",
          pending: "Pending",
          running: "Running",
          succeeded: "Succeeded",
        },
        successDialog: {
          title: "Operation created",
          text: "The operation has been successfully launched!",
        },
      },
      bulkComponentUpdate: {
        action: "Action",
        actionIcons: {
          add: "Add",
          edit: "Edit",
          error: "Error",
        },
        backToDashboardButton: "Back to Dashboard",
        bulkUpdate: "Upload CSV and see the potential changes...",
        bulkUpdateShortDesc: "Add or update generic components by importing CSV from your device",
        bulkUpdateDesc: {
          action: "Provide the CSV to update or add the generic components.",
          instructions:
            "The CSV should have 7 columns that corresponding to the product ID, component ID, component type, languange, key, value and page in order.",
          trigger:
            "After uploading the CSV file, you will be able to review the components that will be added or modified. If you are satisfied with the proposed changes, you will be able to confirm to finalize the updates to the components.",
        },
        componentId: "Component ID",
        createdAt: "Created at",
        createdBy: "Created by",
        errorsIntro: "Here is the list of rules that the CSV violates:",
        errorListTitle: "The provided CSV file contains errors",
        importing: {
          sucessfulImport: "The components are updated.",
          uknownFailure: "Failed to import components.",
        },
        importComponentsButton: "Confirm and Apply Changes",
        key: "Key",
        lang: "Language",
        loadingOperations: "Loading operations...",
        loadingPreview: "Loading changes...",
        metaInfo: {
          title: "Admin - Operations - Bulk Components Update",
        },
        numComponents: "Number of components",
        numElements: "Number of elements",
        page: "Page",
        previewTitle: "Preview of the changes that will be made",
        product: "Product",
        rules: {
          allowedLang: "The language should be an existing one for the corresponding product.",
          columns:
            "There should be 7 columns in the following order: product ID, component ID, component type, languange, key, value, page.",
          invalidFile: "CSV file can not be parsed. Make sure you have the correct CSV file.",
          keyDuplication: "The keys can not be duplicated.",
          linking: "Linked components can not be updated simultaneously.",
          multipleTypes: "Multiple types specified for the same component.",
          naValue: "The provided CSV can not contain NaN values.",
          nonEditableComponent:
            "A component can not be edited since it is a predefined component or the component type specified is wrong.",
          nonFloatValue: "The value column in case of charts needs to contain numbers.",
          numRowsPerLang: "There should be the same number of rows for each language.",
          productExists: "The product IDs must be valid.",
          unsupportedPage: "The allowed component pages are: 'overview' and 'factsheet'.",
          unsupportedType:
            "The allowed component types are: 'bar-chart', 'pie-chart', 'donut-chart', 'table'.",
        },
        type: "Type",
        value: "Value",
      },
    },
    platformSettings: {
      platformSettings: "Platform settings",
      complianceTab: {
        checksActivated: "Checks activated",
        editTitle: "Edit compliance",
        title: "Compliance",
        investorProfile: {
          disclaimerTooltip:
            "This text will be shown to users coming on the Platform and getting asked what their investor profile is (type and country). Make sure to write an appropriate and legal disclaimer.",
          goToInvestorProfilePage: "Open page",
          seeInvestorProfilePage: "See investor profile page",
          showLogoOnInvestorProfile: "Show logo on investor profile page",
          title: "Investor profile",
          tooltip:
            'You can activate the investor profile checks, which means that when a user comes on your Platform for the first time, they will have to select their investor type (retail, professional, institutional), as well as their country. You can then configure which type of investor and from which country is allowed to see each of your product/share class; this can be done in the Admin>Products section, under the "Access restrictions" tab, once you\'ve activate the checks here.',
        },
        notify: {
          saveSuccessText: "Your compliance settings were updated successfully.",
          saveSuccessTextWithLang: (ctx) =>
            `Your compliance settings were updated successfully (language: ${ctx.named("lang")}).`,
        },
        ombudsman: {
          title: "Ombudsman text",
          tooltip:
            "If you need to have a reference to your ombudsman on this Platform, write the text that will be shown to users checking your ombudsman. A link to this text will be visible in the footer of the Platform, next to the Privacy Policy.",
        },
        productDisclaimer: {
          title: "Default product disclaimer",
          tooltip:
            'Set a default product disclaimer that will be used for all products which don\'t have a product-specific disclaimer. You can set a product-specific disclaimer by going on "Admin > Products", then selecting the product of your choice, and setting the product-specific disclaimer in the "General" tab.',
        },
      },
      rolesTab: {
        editTitle: "Edit roles",
        title: "Roles",
        description:
          "By default, only the role \"admin\" exists, a role which gives admin rights on all the products as well as access to the platform settings. It can be useful to have more fine-grained roles, for example giving some users admin access only to some products. In order to create such roles, use the following table. Note that once you've saved the roles, you'll need to go on the {0} page to assign the roles to the users of your choice.",
        table: {
          roleNameHeader: "Role name",
          productsHeader: "Products with admin rights",
          removeRoleTooltip: "Remove role",
          addRoleTooltip: "Add role",
          productsMultiselect: {
            placeholder: "Select product",
            selectLabel: "Press enter to select",
            deselectLabel: "Press enter to remove",
            selectedLabel: "Selected",
            limitText: "and {count} more",
          },
        },
        notify: {
          saveSuccessText: "Your roles were updated successfully.",
        },
      },
      metaInfo: {
        title: "Admin - Platform Settings",
      },
    },
    products: {
      contactUsToFixError: "Please contact us using the contact form to fix this error!",
      langTabError: "Some fields are invalid in the language:",
      overwriteDataAllShareClasses: "Overwrite data of all share classes",
      tabError: "Some fields are invalid",
      unsavedChangesAlert:
        "You have unsaved changes. If you leave without saving, they will be lost.",
      accessRestrictionsTab: {
        allCountries: "All countries",
        countriesAllowedForInstitutionalInvestors: "Countries allowed for institutional investors",
        countriesAllowedForProfessionalInvestors: "Countries allowed for professional investors",
        countriesAllowedForRetailInvestors: "Countries allowed for retail investors",
        editAccessRestrictions: "Edit access restrictions",
        title: "Access restrictions",
        warningEnablingAccessRestrictions:
          'To be able to modify the access restrictions, please activate the investor profile checks in the {0}, under the "Compliance" tab.',
      },
      contactsTab: {
        addAnyPerson: "Add any person that you want to show on your factsheet.",
        additionalText: "Additional text",
        email: "Email",
        imageSquare: "Image (square)",
        name: "Name",
        phone: "Phone",
        role: "Role",
        title: "Contacts",
      },
      dataTab: {
        title: "Data",
        benchmarks: {
          title: "Benchmarks",
        },
        product: {
          title: "Product",
        },
      },
      detailsTab: {
        editDetails: "Edit details",
        key: "Key",
        showOnFactsheet: "Show on Factsheet",
        title: "Details",
        value: "Value",
      },
      dialog: {
        saveFieldInAllShareClasses: {
          title: "Overwrite",
          text: "You are going to overwrite this field in all other share classes of this product, not only the current share class. Are you sure?",
        },
        saveInAllShareClasses: {
          title: "Overwrite",
          text: (ctx) =>
            `You are going to overwrite the "${ctx.named(
              "tabName"
            )}" data of all other share classes of this product with the "${ctx.named(
              "tabName"
            )}" data of this share class (not only the last changes you made). Are you sure?`,
        },
      },
      followersTab: {
        addedByAdmin: "Added by admin",
        addFollowerButton: "Add a follower",
        email: "Email",
        exportCsv: "Export as CSV",
        importCsv: "Import from CSV",
        noDataText: "No one is following this product",
        product: "Product",
        removeFollowerTooltip: "Remove follower for corresponding product",
        sendEmail: "Send an email",
        showAnyShareClass: "Show followers of any share class",
        title: "Followers",
        addFollowerPrompt: {
          email: "Follower's email",
          emailAlreadyExists: "Someone with this email address is already following this product.",
          file: "CSV file",
          language: "Follower's language",
          text: "Before adding a follower manually, you need to make sure that they agree with receiving whatever information you're planning to send them.",
          titleAddFollower: "Add follower",
          titleImportFollowers: "Import followers",
        },
        notify: {
          newFollowerAddedSuccess: "The new follower has been added with success.",
          newFollowerAddedError:
            "There was an issue when adding the new follower. Try again or contact us if the issue persists.",
          noDataInCsv: "No email to add (after filtering email(s) already present).",
          noEmailHeader:
            "Your file is missing a column header representing the emails. Accepted column headers are the following (case insensitive): email or e-mail.",
          followerRemovedSuccess: "The follower has been removed with success.",
          followerRemovedError:
            "There was an issue when removing the follower. Try again or contact us if the issue persists.",
          wrongFormatEmails: (ctx) =>
            `Your file contains email(s) with wrong format: ${ctx.named("emails")}`,
        },
        removeFollowerPrompt: {
          acceptText: "Remove follower",
          text: (ctx) =>
            `Are you sure you want to remove "${ctx.named(
              "followerEmail"
            )}" from following the product "${ctx.named("productName")}"?`,
          title: "Remove follower",
        },
      },
      generalTab: {
        editGeneralField: "Edit general field",
        hidden: "Hidden",
        hiddenProductTooltip: "Hide the product on the platform for non-admin users.",
        icon: "Icon",
        isin: "ISIN",
        title: "General",
      },
      holdingComponentsTab: {
        barChart: "Bar chart",
        data: "Data",
        date: "Date",
        donutChart: "Donut chart",
        header: "Header",
        key: "Key",
        loadingData: "Loading data...",
        noData: "This product doesn't have any holdings data.",
        pieChart: "Pie chart",
        shownOnOverview: "Shown on Overview",
        table: "Table",
        title: "Holding components",
        type: "Type",
        weightPercent: "Weight (in %)",
        componentTitle: {
          assetClasses: "Asset classes",
          currencyExposure: "Currency exposure",
          instrumentTypes: "Instrument types",
          regions: "Regions",
          sectors: "Sectors",
          topHoldings: "Top holdings",
        },
      },
      keyCharacteristicsTab: {
        header: "Header",
        icon: "Icon",
        paragraph: "Paragraph",
        title: "Key characteristics",
      },
      metaInfo: {
        title: "Admin - Products Settings",
      },
      notify: {
        productSaveSuccess: (ctx) =>
          `Your product '${ctx.named("productName")}' config was updated successfully.`,
        productSaveSuccessWithLang: (ctx) =>
          `Your product '${ctx.named(
            "productName"
          )}' config was updated successfully (language: ${ctx.named("lang")}).`,
      },
      selectCard: {
        loadingProducts: "Products are loading...",
        loadingShareClasses: "Share classes are loading...",
        noProducts: "You have no products.",
        selectProduct: "Select a product",
        selectShareClass: "Select a share class",
      },
    },
    users: {
      tabName: "List of users",
      company: "Company",
      country: "Country",
      email: "Email",
      emailCopied: "The email has been copied.",
      investorType: "Investor type",
      name: "Name",
      lastVisit: "Last visit",
      phone: "Phone",
      roles: "Roles",
      metaInfo: {
        title: "Admin - Users List",
      },
    },
  },
  auth: {
    email: "Email",
    errorAlreadyEmailAndPasswordRegister:
      "You are already registered with this email with a password. Please login with this email and your password.",
    errorInternal: "An internal error occurred. Please contact us.",
    or: "or",
    password: "Password",
    login: {
      errorAccountNotExist:
        "You don't have an account on this platform, please create an account and try again.",
      errorInvalidLoginCredentials:
        "The provided credentials do not correspond with any of our user accounts. This discrepancy could be due to an incorrect email or password, the absence of an account associated with the email entered, or the possibility that the email is linked to an alternative sign-in method (such as Google). Please verify your information and try again or create an account if necessary.",
      errorNeedEmailVerification:
        "You need to verify your email before trying to connect on our platform. Please check your emails (and your spam folder) to find the email. If you cannot find it, reset your password.",
      noAccountYet: "No account yet?",
      rememberMe: "Remember me",
      welcomeBack: "Welcome back, please login to your account.",
      dialog: {
        alreadyLoggedInText: "You are already logged in!",
      },
      metaInfo: { title: "Platform - Login" },
      notify: {
        newAccessRight: {
          title: "New access rights",
          text: "You have been automatically logged out because you were given new access rights that require a fresh login to be validated.",
        },
        registrationSuccess: {
          title: "Registration success",
          text: "Registration successful, we sent you a message to validate your email address, check your inbox!",
        },
        requiresRecentLogin: {
          title: "Reauthentication Required",
          text: "To proceed with this action, please log in again as your session has expired. Once reauthenticated, you can try the action again.",
        },
        unexpectedError: {
          title: "Unexpected Error",
          text: "An unexpected error occurred. Please log in again. If the problem continues, contact support for assistance.",
        },
      },
    },
    login2fa: {
      enterCode: "Please enter the 6-digit code from your authenticator app.",
      incorrectCode: " The code is not correct. Please try again.",
      title: "2-Factor Authentication",
      verify: "Verify",
      verifying: "Verifying ...",
    },
    register: {
      alreadyRegistered: "Already registered?",
      confirmPassword: "Confirm password",
      createAccount: "Create account",
      errorAlreadyEmailAndPasswordRegister:
        "You already have an account with this email. Please login to your account.",
      errorAlreadyGoogleRegister:
        "You are already registered with this Google account. Please login with this account.",
      errorAlreadyMicrosoftRegister:
        "You are already registered with this Microsoft account. Please login with this account.",
      fillBelowForm:
        'Fill the below form to create a new account. Fields marked with <span class="text-danger">*</span> are required.',
      registerWith: "Register with: ",
      form: {
        agreeAdvertisementOnly:
          "I explicitly agree that all information provided on this platform is for information and advertisement purposes only and should not be construed as an offer, advice, or recommendation to buy or sell any particular security or to enter into any transaction.",
        certifyAdult: "I certify that I am 18 years old or older.",
        company: "Company",
        confirmPassword: "Confirm password",
        email: "Email",
        firstName: "First name",
        investorProfileCountry: "Country",
        investorProfileType: "Investor type",
        lastName: "Last name",
        needAgree: "You need to agree",
        phone: "Phone",
        privacyPolicy: "privacy policy",
        registeringAccept: "By registering, you accept our {0} and {1}.",
        termsOfUse: "terms of use",
      },
      dialog: {
        alreadyLoggedInText: "You are already logged in!",
      },
      metaInfo: { title: "Platform - Register" },
    },
    register2fa: {
      generatingQrCode: "Generating the QR code ...",
      incorrectCode: " The code is not correct. Please try again.",
      need2fa:
        'To access the platform, please set up 2-factor authentication (2FA). Scan the QR code below or enter the provided secret key into an authenticator app, such as "Google Authenticator" or "Microsoft Authenticator."',
      title: "Set Up 2-Factor Authentication",
      verify: "Verify",
      verifying: "Verifying ...",
    },
    resetPassword: {
      title: "Reset password",
      sendEmail: "Send email",
      dialog: {
        internalErrorText: "An internal error occurred. Please contact us.",
        resetEmailSent: {
          title: "Reset email sent",
          text: (ctx) =>
            `A reset email has been sent to the inbox of ${ctx.named(
              "email"
            )}. If it doesn't appear shortly, check the spam inbox.`,
        },
        unknownEmail: {
          title: "Email doesn't exist",
          text: "The email you provided for resetting the password doesn't exist. To create an account with this email, go to the register page.",
        },
      },
      metaInfo: { title: "Platform - Reset Password" },
    },
  },
  compliance: {
    investorProfile: {
      agreeAndConfirmSelection: "Agree and confirm selection",
      byClickingAcceptDisclaimer:
        "By clicking the button below, I confirm that I have read and accept the above disclaimer and agree with the {0} and the {1} of this platform.",
      pleaseChooseProfile:
        "Please choose your country of domicile and investor profile to proceed.",
      privacyPolicy: "privacy policy",
      termsOfUse: "terms of use",
      title: "Investor profile",
      metaInfo: {
        title: "Platform - Investor Profile",
      },
    },
    ombudsman: {
      title: "Ombudsman",
      metaInfo: {
        title: "Platform - Ombudsman",
      },
    },
    privacyPolicy: {
      endDate: "Crissier, 31st of August 2023",
      title: "Privacy Policy",
      metaInfo: { title: "Platform - Privacy policy" },
      introductionParagraph: {
        title: "Introduction",
        para1:
          "Alquant SA (hereinafter referred to as Alquant, 'we', 'us') is the company operating this platform. Alquant SA respects the privacy of users of its web presence. This means that the personal data is collected, processed and used in accordance with the provisions of applicable data protection law and on the basis of this privacy policy.",
        para2:
          "This also applies - and in particular - to cooperation with partners and third parties which we use for the processing of contracts.",
        para3:
          "By agreeing to the following privacy policy, you consent to Alquant's data handling processes detailed below regarding collection, processing and use of data in accordance with data protection laws and the following provisions. This consent can be revoked at any point in time with future effect.",
        para4:
          "Our data protection practice is therefore consistent with the new Federal Act on Data Protection (nFADP) and, where applicable, the EU General Data Protection Regulation (GDPR).",
      },
      accountableAuthorityParagraph: {
        title: "Accountable authority / EU-Representative",
        switzerland: "Switzerland",
        euRepresentativeSub: {
          title: "EU-Representative",
          para1:
            "In cases referred to in Article 3, paragraph 2 of the Regulation (EU) 2016/679 (General Data Protection Regulation; GDPR) and in accordance with Article 27 of GDPR as representative in the EU is designated and in reference to the Swiss nFADP requirement of a Data Protection Officer function:",
          para3: "You may reach our EU representative at: info(at)alquant.com",
        },
        accountableAuthoritySub: {
          email: "Email",
          phone: "Phone",
          title: "Accountable authority and Data Privacy function according to Swiss nFADP",
          para1: "Responsibility for the processing of personal data is with:",
          para3:
            "For any request related to the protection of your personal information, please contact our privacy officer at: compliance(at)alquant.com.",
        },
      },
      personalDataParagraph: {
        title: "Personal Data",
        para1:
          "Personal data is any information relating to an identified or identifiable natural person; a natural person is considered as being identifiable if they can be identified directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or one or more special characteristics expressing the physical, physiological, genetic, mental, economic, cultural or social identity of this natural person. This includes, for example, your name, your telephone number, your address, as well as all inventory data that you provide us with when registering and creating your client account. Statistical data or anonymised data, which we collect, for example, when you visit our web shop and which cannot be directly linked to your person, is not covered here.",
      },
      purposeOfDataCollectionParagraph: {
        title: "Purposes of Data Collection",
        para1:
          "a) The collection and processing of this data is carried out for the purpose of contract compliance, for the purpose of enabling the use of our website (connection establishment), to ensure system security and stability on a permanent basis, to optimise our web presence, and for internal statistical purposes. We collect, store and process personal data solely in accordance with the applicable statutory regulations and insofar as this is both necessary and required to fulfil and improve the contractual performance obligations between us and the user / client. This also includes the storage of data for any warranty claims after the end of the contract.",
        para2:
          "b) In addition, we use your data within the scope of applicable law for our own marketing purposes in order to optimise our web presence and to draw your attention to services that might be of particular interest to you.",
        para3:
          "c) In addition, collection, processing and use of personal data only takes place if you have given your consent.",
      },
      newsletterParagraph: {
        title: "Newsletter",
        para1:
          "Alquant likes to inform you regularly about current topics and projects around our field of activity via the newsletter. You will receive a newsletter from us only upon express request. We use the opt-in process to send the newsletter, which means that you will receive our newsletter by email provided that you have previously explicitly registered for newsletter delivery. When you have registered for the newsletter, your email address will be used together with your consent for our own promotional purposes until you unsubscribe from the newsletter. You can unsubscribe at any time by sending an email specifying that to our contact address info(at)alquant.com. We are entitled to commission third parties for the technical handling of promotional activities and are entitled to pass on your data for this purpose.",
        para2:
          "The data provided by you when registering for the newsletter (name and email address) will be stored and used only to send the newsletter.",
      },
      legalBasisParagraph: {
        title: "Legal basis for processing",
        para1:
          "We process your personal data primarily to fulfil contractual obligations and our legal obligations. In addition, by accepting this privacy policy, you consent to all processing operations specified in this privacy policy. Of course you can revoke your consent at any time; a declaration to compliance(at)alquant.com is sufficient. Any processing carried out up to this time will not be affected by your cancellation.",
      },
      legitimateInterestParagraph: {
        title: "Legitimate interests for processing",
        para1:
          "In addition to the aforementioned legal basis for data processing, we also process data based on our legitimate interests. This applies in particular, but is not limited to, processing for marketing and promotional purposes. We pursue, in particular, the following interests:",
        list1: {
          el1: "ensuring potential use of the website",
          el2: "preventing fraud",
          el3: "ensuring the security and stability of IT systems",
          el4: "our own marketing purposes",
          el5: "internal statistical purposes",
        },
      },
      disclosureParagraph: {
        title: "Disclosure",
        para1:
          "We only disclose your personal data if you have given your express consent, if there is a legal obligation to do so or if this is required to enforce our rights – in particular, to enforce claims arising from the contractual relationship. In addition, we disclose your data to third parties, insofar as is required for the execution of the contract. We disclose the required data to subsidiaries, transport companies, banks and other service providers for this purpose. These service providers use your data exclusively for order processing and not for other purposes. In addition, we use some services provided by external service providers to optimise your visit to our website. It is (contractually) ensured that these service providers treat your data confidentially and in accordance with the applicable statutory regulations, and that your data is only used for the purpose of the respective service. Any transfer or resale of the data to third parties by Alquant beyond the provisions in this privacy policy is excluded unless the user has given their express consent to the disclosure of their data. In this case, the user can revoke this consent at any time. Processing operations previously performed on the basis of your consent will not be affected.",
        para2:
          "Alquant is entitled to pass your personal data on to third parties abroad if this is required for the execution of the contract. These third parties are bound to the same level of data protection as the provider. If the level of data protection in one country does not correspond to the Swiss level, we contractually ensure that the protection of your personal data corresponds at all times to that in Switzerland.",
      },
      singleSignOnProcedureParagraph: {
        title: "Use of single sign-on procedures",
        para1:
          "On our website, if you have a Google or Microsoft profile, you can sign up to create a customer account or register using the social plugin using the Single Sign On technique. The plugins are labelled with the logo of the provider.",
        para2:
          "When you call up our websites which contain such a plugin, your browser sets up a direct connection with the provider’s computers. The content of the plugin is transmitted by the provider site directly to your browser, which integrates it into the website. By integrating plugins the provider receives the information that you have called up our website. If you are simultaneously logged in to the provider, the provider can assign the visit to your profile.",
        para3:
          "If you do not want the provider to collect data about you via our website, you must log out of the provider before you visit our website. Even if you are logged out, providers collect anonymised data via social plugins set up a cookie for you. If you log into the provider at a later time, these data may be assigned to your profile.",
        para4:
          "If a login is provided via a social login service − e.g. Google Connect – data are exchanged between the provider and our website. In the case of Google Connect that may be, for example, data from your public Google profile. By using such login services you agree to the transfer of data.",
        para5:
          "Please refer to the data protection notices of the provider for the purpose and scope of data collection and the further processing of your data by the provider, as well as your respective rights and setup options to protect your privacy.",
        para7:
          'If you do not want the provider to collect data about you via these cookies, you can select the “block cookies from third-party providers" function in your browser settings. Then if there is embedded content from other providers, the browser does not send any cookies to the server.',
      },
      durationOfRetentionParagraph: {
        title: "Duration of retention",
        para1:
          "We retain your personal data for as long as is necessary to comply with applicable laws, or as we deem appropriate, or as long as it is required for the purposes for which it was collected. We will block or delete your personal data as soon as it is no longer needed and definitely on expiry of the statutory maximum retention period.",
        para2:
          "You can request your personal data to be deleted at any time by sending us a corresponding request to compliance(at)alquant.com. We will comply with your request promptly provided we are not required to retain it for other reasons or need it, for example for the enforcement of claims.",
      },
      furtherDataSourcesParagraph: {
        title: "Further data sources",
        para1:
          "In addition to the data that we collect directly from you, we also obtain data from public registers (such as the Commercial Register) and from third-party providers.",
        para2:
          "Since data sources vary according to the client, no general information can be given regarding said sources. If desired, we are of course willing to disclose our sources of data to you in detail.",
      },
      userRightsParagraph: {
        title: "User rights",
        rightRevocationSub: {
          title: "Right of Revocation",
          para1:
            "Insofar as you have given us consent to collect, store and use personal data in regard to the website or our services, you can revoke this consent at any time. The revocation can be sent by email or in writing to the contact above in section 2: Accountable Authority. The effects of the revocation are limited to the storage and use of personal data, which may not be stored and used without your prior consent based on statutory permission. The revocation also has no effect on previous processing procedures made on the basis of the consent.",
        },
        furtherRightsSub: {
          title: "Further rights",
          informationSub: {
            title: "Information",
            para1:
              "On written request, we will give you information at any time about your stored personal data. The request for information must be made in writing and with proof of identity (copy of ID, passport or other official identification document). Exercising this right is free of charge once a year; for any further request, we reserve the right to charge for cost incurred.",
          },
          correctionSub: {
            title: "Correction, deletion, restriction of processing",
            para1:
              "In addition, you have the option of having your personal data corrected, blocked or deleted by us at any time. Please contact the internal authority, details of which can be found in section 2. Excluded from deletion is solely the data which we need for the completion of outstanding tasks or for the enforcement of existing rights and claims, also data which we are obliged to keep due to statutory regulations. Such data will be blocked.",
          },
          rightAppealSub: {
            title: "Right of appeal",
            para1:
              "We attach great importance to safeguarding the rights of our clients and therefore also to the protection of your personal data. Nevertheless, should you be of the opinion that we process your data unlawfully, you can contact us at any time on +41 41 661 23 45 or by email compliance(at)alquant.com with details of your concern. We will address your concerns to the best of our ability.",
            para2:
              "If, contrary to expectation, our client service cannot provide you with satisfactory assistance, you can submit your concern to the Federal Data Protection Supervisor at any time as a formal complaint, which will then be investigated.",
          },
          dataPortabilitySub: {
            title: "Data portability",
            para1:
              "Upon written request, you also have the right to receive personal data relating to you in a structured, current, machine-readable format (pdf file) for transmission to a third party. We reserve the right to charge for cost incurred.",
          },
        },
      },
      cookiesParagraph: {
        title: "Use of Cookies",
        para1:
          "To improve our product range, we use cookies on our website. A cookie is a small text file which is transferred to your computer when you browse our pages. A cookie can only contain information which we send to your electronic device – they cannot be used to read personal data. If you accept cookies on our site, we do not have access to your personal information but with the help of cookies we can identify your computer. There is no personal data stored in the used cookies so that we cannot draw conclusions about your identity by means of cookies technology. Only pseudonymised information is generated.",
        para2: "We use cookies to verify that you comply with our Terms of Use.",
        para3:
          "We use session-based, temporary cookies: these do not remain on your computer. When you leave our pages, the temporary cookie will also be discarded. With the help of the information gathered, we can analyse the usage patterns and structures of our website and optimise it by improving content or personalisation and simplifying use.",
        para4:
          "In addition, we use persistent cookies: these remain on your computer to simplify shopping, personalisation and registration services on your next visit. For example, these cookies may record what you have chosen to buy while you continue to shop. Also, you only need to enter your password once on web pages that require login. Persistent cookies can be manually removed by the user. They delete themselves after a certain time has elapsed.",
        para5:
          "Cookies are also used by some of our advertising partners (third-party cookies). These cookies help us tailor our site to the specific needs of our clients, for example, by showing advertisements that might actually interest you. Using these cookies enables us to gather information about which products you have viewed or searched for or which pages you have previously visited. These cookies are stored on your hard drive and delete themselves after a certain time. There is also no personal information stored in the cookies used by our advertising partners. Use is pseudonymised so that no conclusion is drawn on your identity.",
        para6:
          "Most browsers (such as Firefox, Chrome, Internet Explorer, Safari, etc.) accept cookies by default. You can allow or block temporary and persistent cookies in the security settings - independently of each other. However, we should point out that certain features are not available on our website and some websites may not display properly if you disable cookies.",
        para7:
          "The data stored in our cookies will not be linked to your personal data (name, address etc.) without your express consent.",
      },
      liabilityForLinksParagraph: {
        title: "Liability for links",
        para1:
          "Alquant expressly states hereby that at the time of setting links no illegal contents were identifiable on the pages to be linked. We have no influence on the current and future design, content or authorship of linked pages. Therefore, we hereby expressly dissociate ourselves from all contents of all linked pages which are changed after setting the link. This statement applies to all links and references on our web presence. Regarding illegal, incorrect or incomplete contents and in particular, for damages resulting from the use or non-use of such information, only the provider of the page to which reference was made is liable and not whoever simply sets the link to the respective publication.",
      },
      webAnalysisParagraph: {
        title: "Web analysis",
        googleAnalyticsSub: {
          title: "Google Analytics",
          para1:
            "This website uses Google Analytics, a web analytics service provided by Google Inc. (Google) for needs-based design of websites. We only receive data evaluations with no personal references.",
          para2:
            "Google Analytics uses cookies: text files which are stored on your computer and which enable analysis of your use of the website. The information generated by the cookie about your use of this website (including your IP address) will be transmitted to and stored on Google servers in the United States. IP anonymisation has been activated on this website so that the IP address of Google users within Member States of the European Union or in other contracting states of the Agreement on the European Economic Area is truncated in advance of transmission. Only in exceptional cases will the full IP address be sent to a Google server in the US and truncated there. Google will use this information on behalf of the operator of the website to evaluate users' use of the website, to compile reports on website activity, and to provide other services to the website operator related to website activity and internet usage. Google will also transmit this information to third parties if required by law or if third parties process this data on behalf of Google. Google will never associate your IP address with other Google data.",
          para3:
            "You can prevent cookies being installed by setting your browser software accordingly. However, we should point out that in this case you may not be able to use all functions of this website to the full extent. By using this website, you consent to the processing of data collected on you by Google in the manner and for the purposes set out above.",
          para4:
            'In addition, you can prevent Google from collecting the data generated by the cookie and related to your use of the website (including your IP address) and the processing of this data by Google by downloading and installing the browser plugin available at <a href="https://tools.google.com/dlpage/gaoptout" >https://tools.google.com/dlpage/gaoptout</a >. For more information about the Google Analytics Terms of Use and Privacy Policy, see',
          para5:
            '<a href="https://www.google.com/analytics/terms/" >https://www.google.com/analytics/terms/</a > and',
          para6:
            '<a href="https://policies.google.com/privacy?hl=en" >https://policies.google.com/privacy?hl=en</a >',
        },
        googleFontsSub: {
          title: "Google Fonts",
          para1:
            'This website also uses Google Fonts (Open Scans). For technical reasons, your IP address must be transmitted to Google so that the fonts can be transferred to your browser. For more information about Google Fonts, visit <a class="break-all" href="https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users" >https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users</a > .',
        },
      },
      securityMeasureParagraph: {
        title: "Security measures",
        para1:
          "We apply technical and organisational security measures to appropriately protect your personal information from unauthorised access, abuse, accidental or intentional manipulation, loss or destruction. To protect your privacy, we use a secure connection for our website, which you can see from the ‘https’ (https stands for Hypertext Transfer Protocol Secure) at the very beginning of the address bar in your browser. In this way, the connection is encrypted via SSL/TSL and your data cannot be intercepted by third parties. Technical security measures to protect your personal data are regularly checked and, if necessary, updated. These principles also apply to companies which process and use personal data on our behalf and in accordance with our instructions.",
      },
      legalBasisGeneralDataProtectionParagraph: {
        title: "Legal basis General Data Protection Regulation",
        para1:
          "In accordance with the provisions of the General Data Protection Regulation (GDPR) applicable from 25 May 2018, we inform you that your consent to the dispatch to the e-mail address you provide will be given on the basis of Art. 6 para. 1 lit. a and Art. 7 GDPR. The corresponding dispatch list is used on the basis of our legitimate interests in accordance with Art. 6 para. 1 lit. f GDPR. We are interested in using a user-friendly and secure website and system that serves our business interests but also meets the expectations of our users and customers.",
      },
      changesParagraph: {
        title: "Changes",
        para1:
          "We reserve the right to change this privacy policy at any time. The privacy policy does not constitute any contractual or other formal legal relationship with or on behalf of any party.",
      },
    },
    termsOfUse: {
      endDate: "Crissier, 3rd of February 2022",
      title: "Terms of Use",
      metaInfo: { title: "Platform - Terms of use" },
      declarationOfAcceptanceParagraph: {
        title: "Declaration of acceptance by the user",
        para1:
          'This website is operated by Alquant SA. By utilizing this website (or a page diverted to this web address; hereinafter referred to in toto as the "Alquant website") you confirm that you have read and understood and accept the following Terms of Use including the Legal Conditions. If you do not accept these Terms of Use please do not visit the Alquant website again.',
      },
      limitedAccessParagraph: {
        title: "Limited access",
        para1:
          "The Alquant website is not intended for legal and natural persons who, for reasons of nationality, business headquarters, place of residence or for any other reasons, are subject to a legal system which bans or restricts in particular",
        list1: {
          el1: "the publication of the contents,",
          el2: "access to the Alquant website,",
          el3: "the activities of a foreign financial services provider,",
          el4: "the admission of financial products (e.g. certificates or funds).",
        },
        para3:
          "Moreover it is not intended for use by legal or natural persons in countries where Alquant SA or any companies associated with it do not have the required registration and authorization to operate.",
        para4:
          "Persons who are subject to any one or all of these restrictions may not access the Alquant website. This applies in particular to citizens of the USA, UK, Canada, Japan, China and Singapore, or persons resident in the afore-mentioned countries. Please find out about the corresponding legal restrictions in your country.",
        para5: "Notice for institutional investors",
        para6:
          'Financial products for professional investors are available only to qualified investors. Under the terms of Art. 10, para. 3, of the Collective Capital Investments Act together with Art. 6 of the Collective Capital Investments Ordinance, qualified investors include institutional investors having a professional treasury, namely banks and security dealers (including their customers who have a written asset management contract), pension funds, insurance companies, fund managers and local and cantonal authorities, as well as industrial and trading companies. By selecting "Professional investors" you confirm that you are a qualified investor according to the definition given above.',
      },
      propertyRightsParagraph: {
        title: "Property rights",
        para1:
          "All elements on the Alquant website are protected as intangible property and are owned by Alquant SA or by third parties. The utilization of the Alquant website does not entail the transfer of any rights, in particular any rights relating to software, brand names or elements of the Alquant website. Copyright indications and brand names may not be changed or removed. Elements of the Alquant website may not be reproduced in their entirety or in part without reference to the full source. The contents of the Alquant website may not be reproduced or utilized for public or commercial purposes without the prior written agreement of Alquant SA.",
      },
      consultingReplacementParagraph: {
        title: "Not a replacement for consulting",
        para1:
          "The information and opinions published on the Alquant website are of a general nature. They are neither intended nor suitable for replacing individual consulting by specialised staff who will take into account the actual circumstances prevailing in a given individual case. For this reason they do not represent legal, fiscal, financial, economic or any other advice and not be utilized as an investment aid.",
      },
      noOffersParagraph: {
        title: "No offers",
        para1:
          "The information and opinions published on the Alquant website do not constitute publicity or recommendations and should not be construed as a demand, an offer or an invitation to submit an offer:",
        list1: {
          el1: "for acquiring or selling investment tools,",
          el2: "for carrying out any sort of transactions,",
          el3: "for completing any other kind of legal business.",
        },
        para3: "The sole purpose of the Alquant website is to provide information.",
      },
      futurePerformanceParagraph: {
        title: "Future performance (indication of risk)",
        para1:
          "The future performance of an investment tool cannot be deduced from past movements in the rate, in other words, the security may rise or fall. How the security has behaved in the past is therefore no guarantee for its future value. In the case of certain investment tools, the loss of the total capital invested is also possible in extreme cases. Moreover, fluctuations in exchange rates may also affect the investment.",
      },
      noGuaranteeParagraph: {
        title: "No guarantee",
        para1:
          "Alquant SA offers no guarantees (including liability towards third parties) nor assurance for",
        list1: {
          el1: "the actuality, precision, reliability or completeness of the contents of the Alquant website,",
          el2: "the results obtained utilizing the Alquant website,",
          el3: "the contents of third parties that are found on or accessible via the Alquant website.",
        },
        para3: "Furthermore, Alquant SA shall not be held liable for and cannot guarantee that",
        list2: {
          el1: "the functions on the Alquant website work without fault and are available without interruption,",
          el2: "the Alquant website and other components utilized through access to the Alquant website are free of viruses, etc.",
        },
        para5: "Alquant SA also offers no guarantee for",
        list3: {
          el1: "the increase or stability of the value of capital invested in investment tools,",
          el2: "the level of future dividends.",
        },
      },
      linksParagraph: {
        title: "Links",
        para1:
          "Alquant SA has not verified third parties' websites linked to the Alquant website and cannot be held liable for the actuality, precision, reliability, completeness or legality of the contents and offers on such websites. The user therefore accesses such web pages at his or her own risk.",
        para2:
          "Web links may only be included in websites outside an Alquant website with the prior agreement of Alquant SA.",
      },
      serviceChangesParagraph: {
        title: "Service changes",
        para1:
          "Alquant SA may change, suspend or discontinue any aspect of the Services at any time, including the availability of any Service feature, database, or content. Alquant SA may also impose limits on certain features and services or restrict the user's access to all or any parts of the Services without notice.",
        para2:
          "Alquant SA reserves the right at its sole discretion to terminate the user's access to the Services without notice.",
      },
      useOfInformationParagraph: {
        title: "Use of information",
        para1:
          "The user may use the Information published on the Alquant website only for internal business use or personal, noncommercial use. In no event shall the user publish, sell, lease, disseminate, retransmit, redistribute, broadcast, circulate or otherwise reproduce, provide or permit access to any <b>Alquant SA</b> Information in any format to anyone; provided, however, that the user may furnish Information to users, on a non-continuous basis, using only the following limited methods: (a) In written advertisements, correspondence, or other literature, or (b) during voice telephonic conversations not entailing computerized voice, automated information inquiry systems, or similar technologies.",
        para2:
          "Use of automated scripting tools to crawl the site or attempting to bypass the API is strictly prohibited and may result in getting blocked from the Alquant website. Excessive requests may result in throttling of your accounts access to data.",
        para3:
          "The use of non-API-based forms of automation, such as scripting the website are prohibited. The use of these techniques may result in the permanent suspension of your account.",
      },
      indemnificationParagraph: {
        title: "Indemnification",
        para1:
          "User (and user's fund, if user is a representative of a fund) jointly and severally agree to indemnify and hold harmless <b>Alquant SA</b>, and its agents, information providers and their agents, representatives, directors, officers, shareholders, partners, predecessors, successors and assigns, from and against any claim or demand, proceeding, damages, liabilities, losses or expenses, from claims made by a third party, arising from user's use of the <b>Alquant SA</b> information which constitutes a violation of the Terms of Use or an improper act or omission.",
        para2:
          "If a user makes use of the Services or any Content other than as expressly permitted under these Terms of Use, he or she may violate copyright and other laws and may be subject to penalties. Without limiting the preceding sentences, the user will not:",
        para3:
          "Engage in or permit any reselling, display, reproduction, copying, translation, modification, adaptation, creation of derivative works from, distribution, transmission, transfer, republication, compilation, decompilation or reverse engineering of the Services, any Content, any portion thereof, or any data, other content or information provided to the user in connection with the use of the Services by Alquant SA or any of its third-party sources, in any form, media or technology now existing or hereafter developed or over any network, including a local area network; Remove, obscure or alter any notice, disclaimer or other disclosure affixed to or contained within the Services, including any copyright notice, trademark or other proprietary rights notice or any other legal notice affixed to, or displayed in association with, the Content; Create a frame or use framing techniques to enclose any portion of the Services or the Content, without express prior written consent from Alquant SA;",
        para4:
          "Impersonate any person, or falsely state or otherwise misrepresent his or her affiliation with any person, in connection with any use of the Services; Breach or attempt to breach the security of the Services or any network, servers, data, or computers or other hardware relating to or used in connection with the Services or any of the Content, whether or not operated by Alquant SA or any third party; nor use with, or distribute through, the Services any software or other tools or devices designed to interfere with or compromise the privacy, security or use of the Services by others or the operations or assets of any person;",
        para5:
          "Violate any applicable law, including, but not limited to securities laws and laws governing investment activity in any country; Restrict or inhibit any other authorized user from using and enjoying the Services; Post, publish, transmit, reproduce, distribute or in any way exploit any of the Content or other information or other materials obtained through the Services for commercial purposes other than as expressly permitted by these Terms of Use or by express prior permission of Alquant SA.",
      },
      exclusionLiabilityParagraph: {
        title: "Exclusion of liability",
        para1:
          "Alquant SA or any companies, partners, boards of directors, employees or representatives linked to it refuse all liability (including for negligence and towards third parties) for direct or indirect damage or consequential damage that may occur in direct or indirect connection with the utilization of the Alquant website.",
      },
      reservationsParagraph: {
        title: "Reservations",
        para1:
          "Alquant SA reserves the right to modify any information, opinions, performance or other data, links, etc. at any time without prior notice to users.",
        para2:
          "Moreover, Terms of Use may also be changed and the use of the Alquant website may also be restricted or terminated without prior notice.",
      },
      placeOfJurisdictionParagraph: {
        title: "Place of jurisdiction",
        para1:
          "Insofar as a legal position arises between Alquant SA and the users of the Alquant website, this is subject to Swiss legislation. The sole place of jurisdiction shall be Lausanne, Switzerland.",
      },
    },
  },
  errors: {
    backHome: "Back to Home",
    error403: {
      paragraphWhenLoggedIn:
        "You don't have the permission to access this page. If your profile is accepted, an admin will soon give you the rights to access it. Please check back in the next 1-2 business days.",
      paragraphWhenLoggedInWithContact: (ctx) =>
        `You don't have the permission to access this page. If your profile is accepted, an admin will soon give you the rights to access it. Please check back in the next 1-2 business days. If you still haven't received the access then, please contact us: ${ctx.named(
          "contactEmail"
        )}.`,
      paragraphWhenNotLoggedIn:
        "This page requires you to be authenticated to access it. Please click on the button below to log into your account. If you don't have an account yet, please register by clicking on the button below.",
      title: "403 - Forbidden access",
      metaInfo: { title: "Platform - 403" },
    },
    error404: {
      paragraph: "The page you are trying to access doesn't exist.",
      title: "404 - Page not found",
      metaInfo: { title: "Platform - 404" },
    },
    unauthorizedInvestorProfile: {
      modifyInvestorProfile: "Modify investor profile",
      title: "Unauthorized investment profile",
      unfortunately:
        "Unfortunately, you cannot have access to this page due to your investment profile.",
      metaInfo: { title: "Platform - Unauthorized Investor Profile" },
    },
  },
  help: {
    contact: {
      address: "Address",
      introText:
        "Don't hesitate to use this form to get in touch with us. We will be happy to address any suggestions or answer any kind of questions you might have related to this platform and our products.",
      recaptchaPrivacyPolicy: "Privacy Policy",
      recaptchaProtect: "This site is protected by reCAPTCHA and the Google {0} and {1} apply.",
      recaptchaTermsOfService: "Terms of Service",
      switzerland: "Switzerland",
      title: "Contact",
      dialog: {
        confirmMessage: {
          title: "Confirm",
          text: "Do you want to send this message?",
        },
      },
      form: {
        country: "Country",
        email: "Email",
        enterMessage: "Enter your message",
        exampleEmail: "john.doe@example.com",
        exampleName: "John Doe",
        investorProfileType: "Investor type",
        name: "Name",
        sendEmail: "Send email",
      },
      metaInfo: { title: "Platform - Contact" },
      notify: {
        errorMessage: {
          title: "Error sending message",
          text: "Sorry, the message couldn't be sent. Retry later or contact us directly by email.",
        },
        messageSent: {
          title: "Message sent",
          text: "The message was successfully sent. We'll come back to you in 1-2 business days.",
        },
      },
    },
    faq: {
      subtitle: "See the following list for all of our frequently asked questions.",
      title: "Have Any Questions?",
      metaInfo: { title: "Platform - FAQ" },
    },
    knowledgeBase: {
      investmentStrategyTechniques: "Investment Strategy Techniques",
      metaInfoTerm: (ctx) => `Knowledge Base - ${ctx.named("term")}`,
      monthlyDataBasis: "Calculated on the basis of monthly data.",
      noResults: "No results found.",
      performanceMetrics: "Performance Metrics",
      search: "Search",
      source: "Source",
      subtitle:
        "Find explanation on general technical terms as well as on some of our techniques that are displayed on this platform.",
      title: "Welcome to our Knowledge Base",
      alpha: {
        title: "Alpha",
        para1:
          "{0} is used to measure the superior performance a strategy or its manager is able to generate compared to a benchmark. The {1} describes the performance after discounting for the risk-free environment and the market-related return (market premium * {2}).",
        para2:
          'The chart above shows an <span class="font-bold">alpha</span> value of 4% which means given the strategy’s market dependency and the current interest rate environment (risk-free return), it was able to generate an additional 4% of performance compared to what can be expected.',
        para3:
          'As indicated in the chart above, the <span class="font-bold">alpha</span> component is the only performance driver that can be attributed to the manager’s skill. Furthermore it indicates that <span class="font-bold">alpha</span> can become a negative number which means that performance has been lost through the active management of the investment. That being said, a positive <span class="font-bold">alpha</span> component can be seen as the component an investor is willing to pay a fee for.',
        para4:
          "The higher the alpha, the better the strategy. A high alpha means that the asset managers achieved to create value.",
      },
      annualizedReturn: {
        title: "Annualized return",
        para1:
          '<span class="font-bold">Annualized returns</span> are cumulative returns re-scaled to a period of one year. This allows investors to better compare returns of different assets that they have owned for different lengths of time.',
        para2:
          'Alternatively, <span class="font-bold">annualized returns</span> can also be interpreted as the one-year return an investor would have earned on average by investing during a given period of time.',
        para3: "Everything else being equal, the higher the annualized return, the better it is.",
      },
      annualizedVolatility: {
        title: "Annualized volatility",
        para1:
          '<span class="font-bold">Annualized volatility</span> is the volatility of an investment that is rescaled to a period of one year. The <span class="font-bold" >volatility</span> itself measures the magnitude of price movements. More technically, <span class="font-bold" >volatility</span> is a statistical measure of the dispersion of returns (specifically the standard deviation of returns) over a period of time. The higher the <span class="font-bold" >volatility</span>, the riskier the investment, because the higher the <span class="font-bold">volatility</span>, the more likely it is that larger swings will occur.',
      },
      beta: {
        title: "Beta",
        para1:
          '<span class="font-bold">Beta</span> measures the dependency of returns. Usually it is used to measure the dependency of a security or an investment strategy on the market’s return. A higher <span class="font-bold" >beta</span> indicates that the security is moving more aggressively than the market whereas a low <span class="font-bold" >beta</span> indicates that the security only captures the market returns partially. The graph below shows trendlines from a high-beta and a low-beta investment and how they have been performing in comparison to the market, whereas the slope of the trendline is the <span class="font-bold" >beta</span> value. The low-beta investment shows a <span class="font-bold">beta</span> &lt; 1 which means it generally moves less than the market but in the same direction while the high-beta investment shows a <span class="font-bold" >beta</span> &gt; 1 and therefore is generating more extreme returns than the market. Thus, the returns in the chart are more widely spread than for the low-beta investment.',
        para2:
          'While rarely found, negative <span class="font-bold">beta</span> values exist and would indicate an inverse relation to the market.',
        para3:
          "When analyzing an investment strategy, investors are looking for well-performing strategies with a low beta. This constellation indicates that the performance of the manager is less dependent on market returns but on the manager's skill.",
      },
      calmarRatio: {
        title: "Calmar ratio",
        para1:
          "A ratio used to determine return relative to drawdown (downside) risk in an investment product.",
        para2: "Everything else being equal, the higher the ratio, the better the investment.",
      },
      correlation: {
        title: "Correlation",
        para1:
          "The Pearson correlation coefficient (PCC) is a statistical measure that assesses the strength and direction of a linear relationship between two data sets. It's calculated as the ratio of the covariance of the two variables to the product of their standard deviations. This normalization makes the PCC a value between -1 and 1, where -1 indicates a perfect negative linear correlation, 1 a perfect positive linear correlation, and 0 no linear correlation. It's important to note that PCC only detects linear relationships, not other types of correlations.",
        para2:
          "Given paired data {(X,Y)} of n pairs, the PCC will be calculated using the following formula:",
      },
      cumulativeReturn: {
        title: "Cumulative return",
        para1:
          'Presented as a percentage, the <span class="font-bold">cumulative return</span> on an investment is the aggregate amount that the investment has gained or lost over time.',
        para2:
          'The <span class="font-bold">cumulative return</span> can be easily deduced from a graph that shows the growth of an investment such as above. Using only the first and last values of the graph, we can get the total cumulative return for a given period of time.',
      },
      informationRatio: {
        title: "Information ratio",
        para1:
          'Investment strategies are often measured against a certain benchmark which mostly consists of one or a mix of many indices. When an investment manager beats the benchmark, it is interesting to the investor to know at what cost the goal has been achieved. The <span class="font-bold" >information ratio</span> is a measure providing a comparison between the excess return against the benchmark and the tracking error which is a measure of consistency between the strategy and the benchmark.',
        para2:
          'The <span class="font-bold">information ratio</span> is calculated the same way as the sharpe ratio given the assumption that the benchmark equals a risk-free return with a volatility of 0%.',
        para3:
          "All other things being equal, if this ratio is positive, the higher it is, the more robust the investment. A high ratio means that daily fluctuations in excess returns with respect to the benchmark are small compared with the average excess return with respect to the benchmark.",
      },
      kurtosis: {
        title: "Kurtosis",
        para: "Kurtosis indicates the peakedness of a distribution. For normal distribution, Kurtosis is 0. The higher the kurtosis of a distribution, the most it is concentrated around its mean.",
      },
      m2RiskAdjustedPerf: {
        title: "M2 measure",
        para1:
          "The M2 (M-square) risk-adjusted performance, also known as M2 measure, assesses the risk-adjusted returns of an investment portfolio. It evaluates the portfolio's returns, factoring in its risk compared to a benchmark, such as the market. Essentially, it represents the difference in the adjusted excess return of a portfolio (P) against the market, adjusting the portfolio to match the market's volatility. Originating from the Sharpe ratio, it offers a substantial benefit by expressing results in percent returns, unlike the Sharpe ratio, which is a non-negative figure and less practical for many investors. This feature of the Modigliani measure makes it significantly easier to understand and apply.",
        para2:
          "Everything else being equal, if the return of the investment is higher than the risk-free rate, the higher the M2 measure, the better the investment. Indeed, in this case the M2 measure increases when the return of the strategy increases, or when the volatility of the strategy decreases with respect to the one of its benchmark.",
      },
      maximumDrawdown: {
        title: "Maximum drawdown",
        para1:
          'A <span class="font-bold">maximum drawdown</span> is the maximum observed loss from a peak to a trough of a portfolio, before a new peak is attained. <span class="font-bold" >Maximum drawdown</span> is an indicator of downside risk over a specified time period.',
        para2:
          '<span class="font-bold">Maximum drawdown</span> is an indicator used to assess the relative riskiness of a strategy versus another, as it focuses on capital preservation, which is a key concern for most investors. A low maximum drawdown is preferred as this indicates that losses from investments were small. If an investment never lost a penny, the <span class="font-bold" >maximum drawdown</span> would be zero. The worst possible <span class="font-bold">maximum drawdown</span> would be 100%, meaning the investment is completely worthless.',
        para3:
          "Everything else being equal, the lower the absolute value of the maximum drawdown (i.e. the closer the maximum drawdown is to 0%), the more reliable the investment.",
        keyPoints: "Key points",
        keyPoint1:
          "Maximum drawdown is a measure of an asset's largest price drop from a peak to a trough.",
        keyPoint2:
          "Maximum drawdown is considered to be an indicator of downside risk, with large maximum drawdowns suggesting that down movements could be volatile.",
        keyPoint3:
          "While maximum drawdown measures the largest loss, it does not account for the frequency of losses, nor the size of any gains.",
      },
      metaInfo: { title: "Platform - Knowledge Base" },
      omegaRatio: {
        title: "Omega ratio",
        para1:
          "The Omega ratio is a risk-return performance measure. It computes the probability weighted ratio of gains versus losses.",
        para2: "Everything else being equal, the higher the ratio, the better the investment.",
      },
      returnVolatilityRatio: {
        title: "Return to volatility ratio",
        para1:
          'The <span class="font-bold">return to volatility ratio</span> represents the average return achieved per unit of volatility (standard deviation), which can be interpreted as a unit of risk. It is therefore informative about how well an investor is compensated for the risk taken (the higher the <span class="font-bold" >return to volatility ratio</span>, the higher the risk compensation).',
        para2:
          'Typically, any <span class="font-bold">return to volatility ratio</span> greater than 1.0 over a prolonged period of time is considered good because it means that the rewards are higher than the risk taken. For example, if the annualized volatility is lower than the annualized return, the <span class="font-bold" >return to volatility ratio</span> will be greater than 1.0.',
        para3:
          'Thus, the <span class="font-bold">return to volatility ratio</span> allows investors to compare very different investments by the same criteria. The <span class="font-bold" >return to volatility ratio</span> allows for apples-to-oranges comparisons.',
        keyPoints: "Key points",
        keyPoint1:
          "A high <span class='font-bold'>return to volatility ratio</span>  indicates how well an investor is compensated for the risk taken.",
        keyPoint2:
          "The <span class='font-bold'>return to volatility ratio</span>  adjusts the past performance of a portfolio to the risk taken by the investor and thus allows a comparison between very different investments.",
      },
      sharpeRatio: {
        title: "Sharpe ratio",
        para1:
          'The <span class="font-bold">sharpe ratio</span> is a measure of risk-adjusted return. It compares the excess return above a risk-free investment to the risk that has been taken to achieve this return, whereas the risk for the investor is defined as volatility. The ratio helps to understand the return that has been achieved by the unit of risk and can be used to compare multiple investment opportunities.',
        para2:
          "Since no investments are totally risk-free, any security with tiny risks can be used as a proxy for risk free returns. Usually, government bonds from stable countries fit the purpose well. We use short-term LIBOR rates to calculate the risk-free return.",
        para3:
          "All other things being equal, if this ratio is defined (i.e. if it’s positive), the higher it is, the more robust the investment. A high ratio means that daily fluctuations in excess returns are small compared with the average excess return.",
      },
      skewness: {
        title: "Skewness",
        para1:
          'The frequency of occurrence of large returns in a particular direction is measured by <span class="font-bold">skewness</span>. If the frequency of occurrence of large negative returns compared to large positive returns is higher, then the distribution displays negative <span class="font-bold">skewness</span>. In case the frequency of large positive returns exceeds that of large negative returns then the distribution displays positive <span class="font-bold">skewness</span>.',
        para2:
          'Investors should look at <span class="font-bold">skewness</span> because it considers the extremes of the returns rather than focusing solely on the average. Short- and medium-term investors in particular need to look at extremes because they are less likely to hold a strategy long enough to be confident that the average will work itself out.',
      },
      sortinoRatio: {
        title: "Sortino ratio",
        para1:
          "The Sortino ratio is similar to Sharpe ratio except it uses downside risk (Downside volatility) in the denominator. Since upside variability is not necessarily a bad thing, Sortino ratio is sometimes more preferable than Sharpe ratio.",
        para2: "Everything else being equal, the higher the ratio, the better the investment.",
      },
      trackingError: {
        title: "Tracking error",
        para1:
          'The <span class="font-bold">tracking error</span> is a measure of the risk of an investment portfolio due to its manager\'s decisions to deviate from its benchmark. The higher it is, the more active the manager is in choosing to deviate from its benchmark.',
        para2:
          "Mathematically, a relevant way to estimate it is to calculate the standard deviation of the difference in return between the portfolio and the benchmark. The one we present on this platform is annualised.",
        para3:
          'It is important to keep in mind that it does not represent the relevance of the manager\'s decisions, but rather the impact they have on the final portfolio. Thus, two portfolios with excellent or bad performance can both have high <span class="font-bold">tracking errors</span> compared to their benchmark.',
        para4:
          "A low tracking error implies that an investment does not deviate much from its benchmark. A high tracking error means that the investment deviates a lot from its benchmark.",
      },
      treynorRatio: {
        title: "Treynor ratio",
        para: 'Similar to Sharpe ratio, Treynor ratio is a measurement of efficiency utilizing the relationship between annualized risk-adjusted return and risk. Unlike Sharpe ratio, Treynor ratio utilizes "market" risk (beta) instead of total risk (standard deviation). Good performance efficiency is measured by a high ratio.',
      },
      valueAtRisk: {
        title: "Value-at-risk",
        para1:
          "VaR is a statistic that quantifies the extent of possible losses within a portfolio over a specific time frame and with a defined probability. VaR 1% over 1 month = x% loss in 1 month with 1% probability. The lower, the better!",
        para2:
          "In practice, it is the quantile of the returns of the strategy corresponding to the p-value (1% or 5%).",
      },
    },
  },
  home: {
    metaInfo: { title: (ctx) => `${ctx.named("orgName")} - Platform` },
    modifyInvestorProfile: "Modify investor profile",
    noProductsAvailable: "No products available for your role and investor profile.",
    search: "Search",
    showMoreProducts: "Show more products",
  },
  products: {
    analysis: {
      analysisPeriod: "Analysis period",
      backtest: "Backtest",
      backtestOrLive: "Backtest or live",
      benchmarks: "Benchmarks",
      live: "Live",
      liveProduct: (ctx) => `${ctx.named("productName")} live`,
      shareClass: "Share class",
      graphs: {
        considerApproximation: "These values should be considered as an approximation.",
        weHave: "We have",
        weWant: "We want",
        titles: {
          assetClassesWeights: "Asset classes weights",
          beta: "Beta",
          constituents: "Constituents",
          contango: "Contango",
          diffMonthlyReturns: "Diff. monthly returns",
          drawdowns: "Drawdowns",
          exposureIndices: "Exposure to indices",
          exposureOverTime: "Exposure over time",
          indicators: "Indicators",
          monthlyReturns: "Monthly returns",
          performance: "Performance",
          ratioVixFutures: "Ratio VIX futures",
          recentDataOverview: "Recent data overview",
          relativeDrawdowns: "Relative drawdowns",
          relativePerformance: "Relative performance",
          riskAdjustedPerformance: "Risk-adjusted performance",
          rollingVRatioLevel: "Rolling v-ratio level",
          statistics: "Statistics",
          returnProfile: "Return profile",
          top10ConstituentsWeight: "Current 10 biggest constituents",
          top5ConstituentsWeight: "Top 5 constituents weight",
          vegaIndicatorConditions: "Vega indicator conditions",
          vix: "VIX",
          vixPremium: "VIX premium",
          volatility: "Volatility",
          weightedPerformance: "Weighted performance",
          yearlyReturns: "Yearly returns",
        },
        tooltips: {
          beta: "This graph shows the beta of our product compared to our benchmark. To compute it, we use data from the last available year, which is why the metric is missing during the first year of life of our product.",
          relativePerformance:
            "This graph shows a comparison of the performance of our product and the performance of our benchmark. To do this, our cumulative returns are divided by the cumulative returns of our benchmark. This means that at 100%, both performance are similar, otherwise the higher the number is, the better the performance of our product is compared to the benchmark.",
          riskAdjustedPerformance:
            "This graph shows the risk-adjusted performance. To compute it, we use data from the last available year, which is why the metric is missing during the first year of life of our product. The risk-adjusted performance is defined as the ratio between the annualized return and the annualized volatility.",
          volatility:
            "This graph shows the annualized volatility (i.e. standard deviation). To compute it, we use data from the last available year, which is why the metric is missing during the first year of life of our product.",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Analysis` },
      tab: {
        historical: "Historical",
        historicalText:
          'For products that are already launched, we distinguish the "Analysis" section between "Live Analysis" and "Historical Analysis". The "Historical Analysis" section aims to illustrate the performance of the product before its inception. The performance is based on a realistic simulation of the strategy. However, no assets under management were involved during the corresponding time period.',
        internal: "Internal",
        live: "Live",
        liveText:
          'For products that are already launched, we distinguish the "Analysis" section between "Live Analysis" and "Historical Analysis". The "Live Analysis" section illustrates the performance since the inception of the product.',
      },
    },
    documentDownload: {
      failure: "The file failed to be downloaded, please contact us if this is unexpected.",
      needAuth: "You need to be authenticated to download this document. Please log in.",
      preparing: "The file is getting prepared for download...",
      seeProductOverview: "See product overview",
      success: "The file has been downloaded successfully.",
      metaInfo: {
        title: (ctx) => `${ctx.named("productName")} - Documents Download`,
      },
    },
    documents: {
      addFile: "Add a file",
      document: "Document",
      nameValidationError:
        "The name is already used by another one of your documents, please choose a different one.",
      noDocument: "No document provided for this product.",
      noArchivedDocument: "No archived document for this product.",
      noteDragAndDrop: "Note: Drag and drop rows to reorder the documents.",
      shareClass: "Share class",
      archiveIconTooltip: "Archive",
      unarchiveIconTooltip: "Unarchive",
      archivedDocumentsCardTitle: "Archived documents",
      dialog: {
        fileDeletion: {
          title: "File deletion",
          text: "You are going to completely delete the file and you won't be able to access it anymore. Are you sure?",
        },
        fileArchiving: {
          title: "File archiving",
          text: 'You are going to archive this file, meaning that it will go in your "archived documents" (just below the documents). Note that it will be possible to unarchive it. Do you want to archive it?',
        },
        fileUnarchiving: {
          title: "File unarchiving",
          text: "You are going to unarchive this file, meaning that it will go back to your normal documents. Do you want to unarchive it?",
        },
        needLogin: {
          text: "In order to download this document, you need to be authenticated.",
          title: "You need to be authenticated",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Documents` },
      notify: {
        savingDocumentsError:
          "An error occurred when trying to update the documents. Retry later, or contact us.",
        savingDocumentsSuccess: "Documents updated successfully.",
        fileDownloadError: "Error when downloading the document, please contact us.",
      },
      prompt: {
        fileDisplayName: "File display name",
        loginRequired: "Login required?",
        uploadFile: {
          acceptText: "Upload file",
          file: "File",
          fileLanguage: "File language",
          shouldConsiderLastFactsheet:
            "Should this file be considered as the last factsheet for the product?",
          title: "Upload file",
        },
        editFile: {
          title: "Edit document",
        },
      },
    },
    embedded: {
      perfGraph: {
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Embedded Performance Graph`,
        },
      },
      perfTable: {
        currency: "Currency",
        inceptionDate: "Inception date",
        name: "Name",
        lastPriceAsOf: "Last price as of",
        mtd: "MTD",
        si: "SI",
        y1: "-1Y",
        y3: "-3Y",
        ytd: "YTD",
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Embedded Performance Table`,
        },
      },
      statsTable: {
        feesIncluded: "Fees are included.",
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Embedded Statistics Table`,
        },
      },
    },
    events: {
      covidCrisis: "COVID-19 crisis",
      crisis2022: "2022 crisis",
      december2018Crisis: "December 2018 crisis",
      euroCrisis: "Euro crisis",
      financialCrisis: "Financial crisis",
      alvolaLive: "Alvola Live",
      convexusLive: "Convexus Live",
      lstsLive: "LSTS Live",
      protechLive: "ProTech Live",
      sinceInception: "Since inception",
      ynnovationLive: "Ynnovation Live",
      calmgard: {
        calmgardCovid19Crisis: "Covid 19 Crisis",
        calmgardEuroCrisisOne: "Euro Crisis I",
        calmgardEuroCrisisTwo: "Euro Crisis II",
        calmgardGreekAndChinaCrisis: "Greek & China Crisis",
        calmgardInterestRateSpeculationOne: "Interest Rate Speculation I",
        calmgardInterestRateSpeculationTwo: "Interest Rate Speculation II",
        calmgardPostFinancialCrisis: "Post Financial Crisis",
        calmgardUkraineWar: "Ukraine War",
      },
      falgom: {
        falgomCoronavirusPandemic: "Coronavirus pandemic",
        falgomFedQuantitativeTapering: "FED Quantitative Tapering",
        falgomGlobalFinancialCrisis: "Global Financial crisis",
        falgomGrowthSlowdownChina: "Growth slowdown China",
        falgomRecessionEurope: "Recession Europe",
        falgomVolmageddon: "Volmageddon",
      },
    },
    factsheet: {
      firstDateToInclude: "First date to include (yyyy-mm-dd)",
      lastDateToInclude: "Last date to include (yyyy-mm-dd)",
      nameValidationError:
        "The name is already used by another one of your documents, please choose a different one.",
      shareClass: "Share class",
      year: "Year",
      cardTitle: {
        assetClasses: "Asset classes",
        contacts: "Contacts",
        currencyExposure: "Currency exposure",
        description: "Description",
        disclaimer: "Disclaimer",
        drawdowns: "Drawdowns",
        monthlyComment: "Monthly comment",
        monthlyPerformance: "Monthly performance",
        monthlyReturns: "Monthly returns",
        historicalPrices: "Historical prices",
        instrumentTypes: "Instrument types",
        productDetails: "Product details",
        regions: "Regions",
        relativePerformance: "Relative performance",
        riskProfile: "Risk profile",
        sectors: "Sectors",
        shareClasses: "Share classes",
        topHoldings: "Top holdings",
        volatility: "Volatility",
        yearlyPerformance: "Yearly performance",
      },
      dragAndDrop: {
        addGenericComponent: "Add component",
        availableGenericComponents: "Generic components",
        availablePredefinedComponents: "Predefined components",
        editFactsheet: "Edit factsheet",
        componentTooBig: "The component is too big to be contained in a single page.",
        saveSuccessText: "The factsheet config was updated successfully.",
        stopEdit: "Stop edit",
        footer: {
          title: "Footer",
          page: "Page",
        },
        forcedElementDeletionWarning: {
          componentsWereRemoved:
            "Some components have been removed from your layout due to their size exceeding the displayable area of a single page or their containing column being too large. The IDs of the removed components are:",
          resolveSolution:
            "To solve this problem, reduce the height of the affected components, place the component in a new single row, or contact us.",
          title: "Forced components removal from layout",
        },
        header: {
          title: "Header",
        },
        style: {
          title: "Style",
        },
      },
      generateFactsheet: {
        downloadFactsheet: "Download factsheet",
        generatingFactsheet: "Generating factsheet ...",
        saveFactsheet: "Save factsheet in Documents",
        notify: {
          generatingError: "Error when generating a factsheet, please contact us.",
          savedInDocumentsSection: "Your factsheet has been saved in the Documents section.",
        },
        prompt: {
          acceptText: "Save factsheet",
          considerLastFactsheet:
            "Should this file be considered as the last factsheet for the product?",
          fileDisplayName: "File display name",
          loginRequired: "Login required?",
          title: "Generate and upload factsheet",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Factsheet` },
      shareClassesTable: {
        bloomberg: "Bloomberg",
        class: "Class",
        currency: "Currency",
        inception: "Inception",
        isin: "ISIN",
        mgmtFee: "Mgmt. fee",
        mtdPerf: "MTD perf.",
        nav: "NAV",
        navDate: "NAV date",
        perfFee: "Perf. fee",
        siPerf: "SI perf.",
        ytdPerf: "YTD perf.",
      },
      statistics: {
        title: "Statistics",
        periods: {
          1: "1M",
          3: "3M",
          6: "6M",
          ytd: "YTD",
          12: "1Y",
          36: "3Y",
          60: "5Y",
          max: "MAX",
        },
      },
      statisticsWithPeriods: {
        title: "Performance",
        periods: {
          1: "1M",
          3: "3M",
          6: "6M",
          ytd: "YTD",
          12: "1Y",
          36: "3Y",
          60: "5Y",
          max: "MAX",
        },
      },
    },
    months: {
      short: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec",
      },
    },
    overview: {
      assetClasses: "Asset classes",
      currencyExposure: "Currency exposure",
      keyCharacteristics: "Key characteristics",
      historicalPrices: "Historical prices",
      instrumentTypes: "Instrument types",
      itd: "ITD return",
      itdDescription: (ctx) =>
        `ITD (inception-to-date) is a period, starting from the product inception (${ctx.named(
          "date"
        )}), and continuing up to the present day.`,
      lastChange: "Last change",
      lastDayChange: "Last day change",
      lastWeekChange: "Last week change",
      lastMonthChange: "Last month change",
      lastQuarterChange: "Last quarter change",
      lastYearChange: "Last year change",
      monthlyReturns: "Monthly returns",
      lastPrice: "Price",
      performance: "Performance",
      productDetails: "Product details",
      productTimeline: "Product timeline",
      regions: "Regions",
      sectors: "Sectors",
      shareClass: "Share class",
      topHoldings: "Top holdings",
      yearlyReturns: "Yearly returns",
      ytd: "YTD return",
      ytdDescription:
        "YTD (year-to-date) is a period, starting from the beginning of the current calendar year, and continuing up to the present day.",
      dragAndDrop: {
        addGenericComponent: "Add component",
        availableGenericComponents: "Generic components",
        availablePredefinedComponents: "Predefined components",
        editOverview: "Edit overview",
        saveSuccessText: "The overview config was updated successfully.",
        stopEdit: "Stop edit",
      },
      follow: {
        buttonText: "Follow",
        buttonTextFollowing: "Following",
        notify: {
          error: "There was an error. Try again or contact us if the issue persists.",
          followSuccess: (ctx) => `You are now following ${ctx.named("productName")}.`,
          unfollowSuccess: (ctx) => `You are not following ${ctx.named("productName")} anymore.`,
        },
        promptNotFollowing: {
          acceptText: "Follow",
          text: "By following this product, you accept to be sent information related to the product from time to time. You will always be able to unfollow to stop receiving such information.",
          title: "Follow product",
        },
        promptFollowing: {
          acceptText: "Unfollow",
          text: "You are currently following this product. Do you want to unfollow it, which means you won't receive information related to the product?",
          title: "Unfollow product",
        },
        promptNotLoggedIn: {
          cancelText: "Create an account",
          text: "If you want to be able to follow this product, you need to be logged into your account. If you don't have an account, you can create one for free.",
          title: "Follow product",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Overview` },
      statsOptions: {
        1: "1 month",
        3: "3 months",
        6: "6 months",
        ytd: "Year to date",
        12: "1 year",
        36: "3 years",
        60: "5 years",
        max: "Since inception",
      },
    },
    useCase: {
      allocationAlvola: "Allocation to Alvola",
      biggestDrawdowns: (ctx) => `Biggest drawdowns of ${ctx.named("benchmark")}`,
      description:
        "Our use case illustrates how adding the Alquant Alvola strategy to an existing equity portfolio can increase returns over the long term while reducing the maximum drawdown. Select your desired equity portfolio and allocation to the Alquant Alvola strategy and discover how the combination would have performed in the past (before the inception of our product which was on the 02 Jun 2021). In addition, you will also find the performance during the 10 largest drawdown periods of your selected equity portfolio.",
      drawdowns: "Drawdowns",
      equityPortfolio: "Equity portfolio",
      historicalResults: "Historical results",
      portfolioAllocation: "Portfolio allocation",
      protectPortfolio: "Protect your portfolio with Alvola",
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Use Case` },
    },
  },
  settings: {
    metaInfo: { title: (ctx) => `Settings - ${ctx.named("name")}` },
    changePassword: {
      buttonReset: "Send an e-mail to reset the password",
      title: "Change password",
      notify: {
        success: {
          title: "Reset email sent",
          text: (ctx) =>
            `A reset email has been sent to the inbox of ${ctx.named(
              "email"
            )}. If it doesn't appear shortly, check the spam inbox.`,
        },
        failure:
          "We couldn't change your password, please contact us using the contact form to fix this error!",
      },
    },
    deleteAccount: {
      deleteYourAccount: "Delete your account",
      title: "Delete account",
      notify: {
        success: "Your account was successfully deleted.",
      },
      dialog: {
        permanentlyDeleteData: {
          text: "Deleting your account will permanently delete all your data.",
          title: "Confirm",
        },
        recentLoginNeeded: "Recent login needed",
      },
    },
    editProfile: {
      company: "Company",
      email: "Email",
      firstName: "First name",
      investorProfileCountry: "Country",
      investorProfileType: "Investor type",
      lastName: "Last name",
      phone: "Phone",
      title: "Edit profile",
      requiredFields: 'Fields marked with <span class="text-danger">*</span> are required.',
      dialog: {
        askValidation: {
          title: "Personal details verification",
          text: "Please check your personal details and save them when you have finished.",
        },
      },
      notify: {
        errorText:
          "We couldn't update your data, please contact us using the contact form to fix this error!",
        successText: "The update was a success",
      },
    },
    overview: {
      email: "Email",
      name: "Name",
      title: "Account overview",
    },
  },
};
