import { Role } from "@/acl/roles";
import { BI_CAPITAL_PRODUCTS_DETAILS } from "@/assets/constants/products/bi-capital";

export default {
  orgName: "B&I Capital",
  showHomePageHeader: false,
  logoPath: require("@/assets/images/logo/bi-capital.png"),
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/bi-capital.jpg"),
  },
  products: BI_CAPITAL_PRODUCTS_DETAILS,
  hasUpdatablePriceData: true,
  possibleRoles: [Role.superAdmin, Role.admin, Role.viewer],
  admin: true,
  availableLanguages: ["en"],
  followFeatureActivated: true,
  colorScheme: [
    "#21264F",
    "#FFB34C",
    "#87CCB9",
    "#AE323D",
    "#965D4A",
    "#FFF4E5",
    "#595959",
    // Same colors with less opacity
    "#8F96D0",
    "#FFD194",
    "#B7E0D5",
    "#D97A82",
    "#C69B8C",
    "#D9D9D9",
    // Same colors with even less opacity
    "#C7CAE8",
    "#FFE1B7",
    "#CFEBE3",
    "#E5A6AC",
    "#D9BCB2",
  ],
};
