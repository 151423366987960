import { Role } from "@/acl/roles";
import { CHAMELEON_PRODUCTS_DETAILS } from "@/assets/constants/products/chameleon";

export default {
  orgName: "Chameleon",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/chameleon.svg"),
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/chameleon.jpg"),
  },
  products: CHAMELEON_PRODUCTS_DETAILS,
  hasHoldingData: true,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "de"],
  followFeatureActivated: true,
  colorScheme: [
    "#AD8E49",
    "#3478B4",
    "#50A55F",
    "#7D8081",
    // Same colors with less opacity
    "#AD8E49B2",
    "#3478B4B2",
    "#50A55FB2",
    "#7D8081B2",
    "#AD8E497F",
    "#3478B47F",
    "#50A55F7F",
    "#7D80817F",
    "#AD8E4919",
    "#3478B419",
    "#50A55F19",
    "#7D808119",
  ],
};
